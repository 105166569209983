import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';

import CustomModal from '../../RiverusUI/Components/CustomModal';
import DateRangePicker from '../../RiverusUI/Components/DateRangePicker';

interface IProp {
  open: boolean;
  onClose: VoidFunction;
  title: string;
  setDateRangeParams: Dispatch<SetStateAction<any>>;
  dateRange: any;
}

const DateRangeModel: React.FC<IProp> = ({
  open,
  onClose,
  title,
  setDateRangeParams,
  dateRange,
}) => {
  const [startEndDate, setStartEndDate] = useState<any>({
    start: null,
    end: null,
  });

  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = () => {
    const start_date = dayjs(startEndDate?.start).isValid()
      ? dayjs(startEndDate?.start).format('YYYY-MM-DD')
      : null;
    const end_date = dayjs(startEndDate?.end).isValid()
      ? dayjs(startEndDate?.end).format('YYYY-MM-DD')
      : null;
    if (start_date && end_date) {
      setDateRangeParams({ start: start_date, end: end_date });
      onClose();
    } else {
      const msg = `Please select ${!startEndDate?.start ? 'start' : 'end'} date!`;
      enqueueSnackbar(msg, {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  useEffect(() => {
    if (dateRange?.start && dateRange?.end) {
      setStartEndDate({
        start: dayjs(dateRange?.start),
        end: dayjs(dateRange?.end),
      });
    }
  }, [dateRange?.start, dateRange?.end]);

  return (
    <CustomModal title={title} open={open} handleClose={onClose}>
      <Stack mt={3} spacing={2}>
        <DateRangePicker
          value={startEndDate}
          startName="From"
          endName="To"
          disableFuture
          onChange={(newValue: any) => setStartEndDate(newValue)}
        />

        <Stack direction="row" justifyContent="space-between" width="100%">
          <Stack direction="row">
            <LoadingButton onClick={onSubmit} variant="contained">
              filter
            </LoadingButton>

            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
          <Button
            variant="text"
            onClick={() => {
              setStartEndDate({
                start: null,
                end: null,
              });
              setDateRangeParams({
                start: null,
                end: null,
              });
            }}
          >
            Clear Filter
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default DateRangeModel;
