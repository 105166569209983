import { authApi } from './authApi';

const baseUrl = process.env.REACT_APP_RIVERUS_CLM_API + 'keycloak';

const APIPaths = {
  userUrl: baseUrl + '/users/',
  groupUrl: baseUrl + '/groups/',
  roleUrl: baseUrl + '/roles/',
};

// users
export const createUser = async (payload: any) => {
  return await authApi.post(`${APIPaths.userUrl}`, payload);
};

export const editUser = async (payload: any) => {
  return await authApi.put(`${APIPaths.userUrl}${payload?.id}/`, payload?.body);
};

export const editUserGroups = async (payload: any) => {
  return await authApi.put(
    `${APIPaths.userUrl}${payload?.id}/groups/`,
    payload?.body
  );
};

export const deleteUserGroups = async (payload: any) => {
  return await authApi.delete(`${APIPaths.userUrl}${payload?.id}/groups/`, {
    data: payload?.body,
  });
};

export const editUserRoles = async (payload: any) => {
  return await authApi.post(
    `${APIPaths.userUrl}${payload?.id}/roles/`,
    payload?.body
  );
};

export const deleteUserRoles = async (payload: any) => {
  return await authApi.delete(`${APIPaths.userUrl}${payload?.id}/roles/`, {
    data: payload?.body,
  });
};

// Users Credentials
export const getUsersCredentials = async (id: string) => {
  const response = await authApi.get(`${APIPaths.userUrl}${id}/credentials/`);
  return response;
};

export const setUsersCredentials = async (payload: any) => {
  return await authApi.put(
    `${APIPaths.userUrl}${payload?.id}/reset-password/`,
    payload?.body
  );
};

export const deleteUsersCredentials = async (payload: any) => {
  return await authApi.delete(
    `${APIPaths.userUrl}${payload?.id}/credentials/${payload?.credId}/`
  );
};

// Groups
export const fetchKeycloakGroups = async () => {
  return await authApi.get(`${APIPaths.groupUrl}`);
};

export const createGroup = async (payload: any) => {
  return await authApi.post(`${APIPaths.groupUrl}`, payload);
};

export const deleteGroup = async (id: string) => {
  return await authApi.delete(`${APIPaths.groupUrl}${id}/`);
};

export const editGroup = async (payload: any) => {
  return await authApi.put(
    `${APIPaths.groupUrl}${payload?.id}/`,
    payload?.body
  );
};

// Roles
export const fetchKeycloakRoles = async () => {
  return await authApi.get(`${APIPaths.roleUrl}`);
};

export const createRole = async (payload: any) => {
  return await authApi.post(`${APIPaths.roleUrl}`, payload);
};

export const deleteRole = async (id: string) => {
  return await authApi.delete(`${APIPaths.roleUrl}${id}/`);
};

export const editRole = async (payload: any) => {
  return await authApi.put(`${APIPaths.roleUrl}${payload?.id}/`, payload?.body);
};
