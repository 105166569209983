import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import { Stack, Switch, Tooltip, Typography } from '@mui/material';
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isArray } from 'lodash-es';
import { useSnackbar } from 'notistack';
import { useDebouncedCallback } from 'use-debounce';

import CreateUsersModal from './CreateUsersModal';
import { sortListAlphabetical } from '../Draft/Component/Helper';
import { IObject } from '../interfaces/IObject';
import ReusableConfirmationModal from '../RiverusUI/Components/ReusableConfirmationModal';
import DataGridTable from '../RiverusUI/DataGrid/DataGridTable';
import { GridCellExpand } from '../RiverusUI/DataGrid/GridCellExpand';
import TableAsyncSelectInput from '../RiverusUI/DataGrid/TableAsyncSelectInput';
import TableChipList from '../RiverusUI/DataGrid/TableChipList';
import TableHeaderWithSearch from '../RiverusUI/DataGrid/TableHeaderWIthSearch';
import TableSearchInputComponent from '../RiverusUI/DataGrid/TableSearchInputComponent';
import { getUsers } from '../Services/Approval';
import { deleteExternalUser } from '../Services/Draft';
import { QueryKeyGenerator } from '../Utils/QueryKeyGenerator';

interface Props {
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  handleCredentials: any;
  editApproval: any;
  filters?: IObject;
  editDetails?: boolean;
  groupData?: any;
  rolesData?: any;
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  rowCount: number;
}

const UsersTable = (props: Props) => {
  const {
    data,
    isLoading,
    pageNumber,
    setPageNumberChange,
    setFilters,
    handleCredentials,
    editApproval,
    filters,
    editDetails,
    groupData,
    rolesData,
    setSelectedRow,
    rowCount,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const [searchUsers, setSearchUsers] = useState<boolean>(false);
  const [searchGroups, setSearchGroups] = useState<boolean>(false);
  const [searchRoles, setSearchRoles] = useState<boolean>(false);
  const [openCreateUserModal, setOpenCreateUserModal] =
    useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>();

  const { data: authorData } = useQuery({
    queryKey: ['author'],
    queryFn: getUsers,
    select: (response) => {
      const users = response?.users || [];
      return users.map((user: any) => ({
        ...user,
        name: `${user.first_name} ${user.last_name}`,
      }));
    },
  });

  const isActionDisabled = useMemo<boolean>(
    () => selectedUser?.length > 1,
    [selectedUser]
  );

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumberChange(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const { mutate: deleteExternalUserMutation, isPending: loadingButton } =
    useMutation({
      mutationKey: ['delete-external-user'],
      mutationFn: deleteExternalUser,
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: [QueryKeyGenerator.getRequestApproval(), 'all-users'],
        });
        enqueueSnackbar(
          `${!selectedUser?.is_active ? 'Internal user Activated successfully!' : 'Internal user Inactivated successfully!'}`,
          {
            variant: 'success',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
        setOpenDeleteModal(false);
      },
      onError: () => {
        enqueueSnackbar(
          `${!selectedUser?.is_active ? 'Failed to activate Internal user!' : 'Failed to inactivate Internal user!'}`,
          {
            variant: 'error',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      },
    });

  const handleToggleActive = () => {
    const payload = {
      id: selectedUser?.id,
      body: {
        is_active: selectedUser?.is_active ? false : true,
      },
    };
    deleteExternalUserMutation(payload);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleRoleClick = useCallback(
    (roles: any) => {
      handleFilterChange('roles', roles);
      setSearchRoles(true);
    },
    [handleFilterChange]
  );

  const handleGroupClick = useCallback(
    (groups: any) => {
      handleFilterChange('groups', groups);
      setSearchGroups(true);
    },
    [handleFilterChange]
  );

  const columns = React.useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'users',
        headerName: 'Users',
        flex: 4,
        minWidth: 100,
        sortable: false,
        renderCell: (Params) => {
          const fullName = `${Params?.row?.first_name} ${Params?.row?.last_name}`;
          return (
            <GridCellExpand
              value={fullName}
              width={Params?.colDef?.computedWidth}
            />
          );
        },
        renderHeader: () => {
          return searchUsers ? (
            <TableSearchInputComponent
              key="users"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, users: null }));
                setSearchUsers(false);
              }}
              placeholder="Search Users"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key={
                    isArray(filters?.users)
                      ? (filters?.users?.[0] as string)
                      : 'users'
                  }
                  options={sortListAlphabetical(authorData || [])}
                  label="Select Users"
                  valueKey="id"
                  value={filters?.users ? filters.users : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('users', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch title="Users" setIsSearch={setSearchUsers} />
          );
        },
      },
      {
        field: 'roles',
        headerName: 'Roles',
        flex: 4,
        minWidth: 100,
        sortable: false,
        renderCell: (params) => {
          const selectedFilters: any = filters?.roles || [];
          let reOrderRoles = params?.row?.roles || [];
          if (selectedFilters?.length) {
            const filterSelectedRoles = reOrderRoles?.filter((item: any) =>
              selectedFilters?.includes(item)
            );
            const filterNotSelectedRoles = reOrderRoles?.filter(
              (item: any) => !selectedFilters?.includes(item)
            );
            reOrderRoles = [...filterSelectedRoles, ...filterNotSelectedRoles];
          }
          return (
            <TableChipList
              id={`roles-${params?.row?.id}`}
              labelKey=""
              valueKey=""
              chipColor="#C4DBFF"
              list={reOrderRoles}
              onClick={handleRoleClick}
            />
          );
        },
        renderHeader: () => {
          return searchRoles ? (
            <TableSearchInputComponent
              key="roles"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, roles: null }));
                setSearchRoles(false);
              }}
              placeholder="Search Roles"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key={
                    isArray(filters?.roles)
                      ? (filters?.roles?.[0] as string)
                      : 'roles'
                  }
                  options={sortListAlphabetical(rolesData || [])}
                  label="Select Roles"
                  valueKey="name"
                  value={filters?.roles ? filters.roles : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('roles', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch title="Roles" setIsSearch={setSearchRoles} />
          );
        },
      },
      {
        field: 'groups',
        headerName: 'Groups',
        flex: 4,
        minWidth: 100,
        sortable: false,
        renderCell: (params) => {
          const selectedFilters: any = filters?.groups || [];
          let reOrderGroups = params?.row?.groups || [];

          if (selectedFilters?.length) {
            const filterSelectedGroups = reOrderGroups?.filter((item: any) =>
              selectedFilters?.includes(item)
            );
            const filterNotSelectedGroups = reOrderGroups?.filter(
              (item: any) => !selectedFilters?.includes(item)
            );
            reOrderGroups = [
              ...filterSelectedGroups,
              ...filterNotSelectedGroups,
            ];
          }
          return (
            <TableChipList
              id={`groups-${params?.row?.id}`}
              labelKey=""
              valueKey=""
              chipColor="#DCBDE7"
              list={reOrderGroups}
              onClick={handleGroupClick}
            />
          );
        },
        renderHeader: () => {
          return searchGroups ? (
            <TableSearchInputComponent
              key="groups"
              setIsSearch={() => {
                setFilters((prev) => ({ ...prev, groups: null }));
                setSearchGroups(false);
              }}
              placeholder="Search Groups"
              renderCustomInput={() => (
                <TableAsyncSelectInput
                  key={
                    isArray(filters?.groups)
                      ? (filters?.groups?.[0] as string)
                      : 'groups'
                  }
                  options={sortListAlphabetical(groupData || [])}
                  label="Select Groups"
                  valueKey="path"
                  value={filters?.groups ? filters.groups : []}
                  onChange={(value: string[]) =>
                    handleFilterChange('groups', value)
                  }
                />
              )}
            />
          ) : (
            <TableHeaderWithSearch
              title="Groups"
              setIsSearch={setSearchGroups}
            />
          );
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        flex: 3,
        minWidth: 100,
        getActions: (params) => {
          return [
            <Tooltip
              key="edit"
              title={
                !params?.row?.is_active ? 'Inactive User Details' : 'Edit User'
              }
              arrow
            >
              <GridActionsCellItem
                icon={<EditOutlinedIcon />}
                label="Edit"
                disabled={isActionDisabled}
                onClick={() => editApproval(params?.row)}
              />
            </Tooltip>,
            <Tooltip
              key="password"
              title={
                !params?.row?.is_active
                  ? 'Credential Details'
                  : 'Manage Credentials'
              }
              arrow
            >
              <GridActionsCellItem
                icon={<VpnKeyOutlinedIcon />}
                label="Password"
                disabled={isActionDisabled}
                onClick={() => handleCredentials(params.row)}
              />
            </Tooltip>,
          ];
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'status',
        flex: 2,
        minWidth: 80,
        sortable: false,
        renderCell: (params: any) => {
          const isActive = params?.row?.is_active;
          const user =
            `${params?.row?.first_name} ${params?.row?.last_name}`.trim();

          return (
            <Tooltip
              title={
                isActive
                  ? `Click here to disable ${user}`
                  : `Click here to enable ${user}`
              }
              arrow
            >
              <Switch
                checked={isActive}
                onChange={() => {
                  setSelectedUser(params?.row);
                  setOpenDeleteModal(true);
                }}
              />
            </Tooltip>
          );
        },
      },
    ],
    [
      data,
      filters,
      handleFilterChange,
      searchUsers,
      searchGroups,
      searchRoles,
      setFilters,
      isActionDisabled,
      editApproval,
      handleCredentials,
    ]
  );

  return (
    <>
      <DataGridTable
        rows={data || []}
        columns={columns}
        rowCount={rowCount}
        isLoading={isLoading}
        setSelectedRow={setSelectedRow}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        checkboxSelection
      />
      {openCreateUserModal && (
        <CreateUsersModal
          open={openCreateUserModal}
          onClose={() => setOpenCreateUserModal(false)}
          editDetails={editDetails}
        />
      )}
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          title={selectedUser?.is_active ? 'Inactivate User' : 'Activate User'}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
          onConfirm={handleToggleActive}
          buttonLoading={loadingButton}
        >
          <Stack spacing={1}>
            <Typography>Are you sure?</Typography>
            <Typography>
              {`The selected user will be ${selectedUser?.is_active ? 'Inactivated' : 'Activated'}.`}
            </Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </>
  );
};

export default UsersTable;
