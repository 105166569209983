import React, { useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, Chip, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';

import AssignRolesModal from './AssignRolesModal';
import CustomModal from '../RiverusUI/Components/CustomModal';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  rolesData: any;
  editDetails: any;
  usersTableKey: any;
}

const AddRemoveRolesModal: React.FC<Props> = ({
  open,
  onClose,
  rolesData,
  editDetails,
  usersTableKey,
}) => {
  const methods = useForm();
  const { setValue, watch } = methods;
  const selectedRoles = watch('roles');

  const [modalAction, setModalAction] = useState<'assign' | 'unassign' | null>(
    null
  );

  const autoSelectedRolesIds = useMemo(() => {
    return (
      rolesData
        ?.filter((role: any) => editDetails?.roles?.includes(role?.name))
        ?.map((role: any) => role?.name) || []
    );
  }, [editDetails?.roles, rolesData]);

  useEffect(() => {
    if (editDetails) {
      setValue('roles', autoSelectedRolesIds);
    }
  }, [editDetails, setValue, autoSelectedRolesIds]);

  const columns = [
    { field: 'name', headerName: 'Role Name', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params: any) => {
        return (
          <>
            {selectedRoles?.includes(params.row.name) && (
              <Chip
                style={{ background: '#e1c4d3', margin: 0 }}
                label={'Assigned'}
              />
            )}
          </>
        );
      },
      width: 120,
    },
  ];

  const rows = rolesData?.map((role: any) => ({
    id: role.id,
    name: role.name,
    description: role.description,
  }));

  return (
    <CustomModal
      maxWidth="md"
      title="Role Mapping"
      open={open}
      handleClose={onClose}
    >
      <Stack width="600px" height="29rem" sx={{ padding: '30px 10px 10px' }}>
        <DataGrid
          rows={rows}
          columns={columns.map((col) => ({ ...col, sortable: false }))}
          hideFooterPagination
          disableColumnMenu
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '&.MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'riPrimary.10',
              fontSize: 16,
              borderRadius: '50px',
              borderBottom: 'none',
            },
          }}
        />

        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          spacing={1}
        >
          <Stack direction="row">
            <Button
              variant="contained"
              onClick={() => setModalAction('assign')}
            >
              Assign Roles
            </Button>
            {editDetails?.roles?.length > 0 && (
              <Button
                variant="outlined"
                onClick={() => setModalAction('unassign')}
              >
                Unassign Roles
              </Button>
            )}
          </Stack>
          <Button
            variant="outlined"
            startIcon={<CloseIcon />}
            onClick={onClose}
          >
            Cancel
          </Button>
        </Stack>
      </Stack>
      {modalAction && (
        <AssignRolesModal
          open={!!modalAction}
          onClose={() => setModalAction(null)}
          rolesData={rolesData}
          editDetails={editDetails}
          usersTableKey={usersTableKey}
          autoSelectedRolesIds={autoSelectedRolesIds}
          modalClose={onClose}
          actionType={modalAction}
        />
      )}
    </CustomModal>
  );
};

export default AddRemoveRolesModal;
