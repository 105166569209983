import React from 'react';

import { Button, Stack, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import ControlledCheckbox from '../../RiverusUI/Components/ControlledCheckbox';
import CustomModal from '../../RiverusUI/Components/CustomModal';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
  handleRenewConfirmation: (renewal_type: string) => void;
}

const RenewConfirmation: React.FC<IProps> = ({
  open,
  onClose,
  handleRenewConfirmation,
}) => {
  const { handleSubmit, control, setValue } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = (data: any) => {
    if (!data?.template && !data?.request_draft) {
      enqueueSnackbar('Please select any one option!', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    } else {
      if (data?.template) {
        handleRenewConfirmation('template');
      }
      if (data?.request_draft) {
        handleRenewConfirmation('draft');
      }
    }
  };

  return (
    <CustomModal
      open={open}
      handleClose={onClose}
      title="Renew Contract"
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack mt={2}>
          <Typography fontWeight="700">Select any one option</Typography>
          <ControlledCheckbox
            name="template"
            label="Generate renewal draft using the same template from which the contract was created"
            control={control}
            onChange={() => {
              setValue('request_draft', false);
            }}
          />

          <ControlledCheckbox
            name="request_draft"
            label="request a renewal draft from legal"
            control={control}
            onChange={() => {
              setValue('template', false);
            }}
          />

          <Stack direction="row">
            <Button variant="contained" type="submit">
              Renew Contract
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </CustomModal>
  );
};

export default RenewConfirmation;
