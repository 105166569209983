import React, { FC } from 'react';

import { Stack, Typography } from '@mui/material';
import { SvgIconProps } from '@mui/material/SvgIcon';
import dayjs from 'dayjs';

interface IProps {
  title: string;
  date?: string;
  subTitle?: string;
  icon?: React.ReactElement<SvgIconProps>;
}

const AssigneeWithDate: FC<IProps> = ({ title, date, subTitle, icon }) => {
  const formatDate = (newDate?: any): string | undefined => {
    return newDate?.replace(/\.\d{6}/, '')?.replace(/\+00:00$/, 'Z');
  };
  const cleanedDate = formatDate(date);
  const timezoneRegex = /\s([A-Za-z]{3,4})$/;
  const validatedDate = cleanedDate
    ? dayjs(cleanedDate, [
        'DD/MM/YYYY, h:mm A [ZZZ]',
        'YYYY-MM-DD',
        'DD-MM-YYYY',
        'YYYY-MM-DD HH:mm:ss',
        'YYYY-MM-DDTHH:mm:ssZ',
      ])
    : null;
  const formattedDate =
    validatedDate && validatedDate.isValid()
      ? (() => {
          const datePart = validatedDate.format('DD-MM-YYYY');
          const timeAndZone = cleanedDate?.match(timezoneRegex);
          if (timeAndZone) {
            const timezone = timeAndZone[1];
            const timePart = validatedDate.format(`h:mm A [${timezone}]`);
            return `${datePart}, ${timePart}`;
          }
          return datePart;
        })()
      : date || subTitle;

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {icon && React.cloneElement(icon, { fontSize: 'small' })}{' '}
      <Typography variant="subtitle2" fontWeight={700}>
        {title}
      </Typography>
      <Typography variant="subtitle2">{formattedDate}</Typography>
    </Stack>
  );
};

export default AssigneeWithDate;
