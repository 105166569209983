import React, { useEffect, useMemo } from 'react';

import { Button, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import CustomModal from '../RiverusUI/Components/CustomModal';
import { deleteUserGroups, editUserGroups } from '../Services/keycloak';
import { QueryKeyGenerator } from '../Utils/QueryKeyGenerator';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  groupData: any;
  editDetails: any;
  usersTableKey: any;
}

const AddGroupsModal: React.FC<Props> = ({
  open,
  onClose,
  groupData,
  editDetails,
  usersTableKey,
}) => {
  const methods = useForm();
  const { setValue, watch } = methods;
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const selectedGroups = watch('groups') || [];

  const autoSelectedIds = useMemo(() => {
    return (
      groupData
        ?.filter((group: any) => editDetails?.groups?.includes(group?.path))
        ?.map((group: any) => group.id) || []
    );
  }, [editDetails?.groups, groupData]);

  useEffect(() => {
    if (editDetails) {
      setValue('groups', autoSelectedIds);
    }
  }, [editDetails, setValue, autoSelectedIds]);

  const { mutate: editUserGroupMutation } = useMutation({
    mutationFn: editUserGroups,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeyGenerator.getRequestApproval(),
          'all-users',
          usersTableKey,
        ],
      });
      enqueueSnackbar('Group added successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to add group!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: deleteUserGroupMutation } = useMutation({
    mutationFn: deleteUserGroups,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeyGenerator.getRequestApproval(),
          'all-users',
          usersTableKey,
        ],
      });
      enqueueSnackbar('Group removed successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to remove group!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const handleGroupToggle = (groupId: string) => {
    const group = groupData.find((item: any) => item.id === groupId);

    if (group) {
      const updatedGroups = selectedGroups?.includes(groupId)
        ? selectedGroups.filter((id: string) => id !== groupId)
        : [...selectedGroups, groupId];

      const payload = {
        id: editDetails?.keycloak_id,
        body: { id: group.id, path: group.path },
      };

      if (updatedGroups.includes(groupId)) {
        editUserGroupMutation(payload, {
          onSuccess: () => {
            setValue('groups', updatedGroups);
          },
        });
      } else {
        deleteUserGroupMutation(payload, {
          onSuccess: () => {
            setValue('groups', updatedGroups);
          },
        });
      }
    }
  };

  const columns = [
    { field: 'name', headerName: 'Group Name', flex: 1 },
    { field: 'path', headerName: 'Path', flex: 1 },
    {
      field: 'action',
      headerName: 'Action',
      renderCell: (params: any) => {
        return (
          <Button
            onClick={() => handleGroupToggle(params.row.id)}
            variant={
              selectedGroups?.includes(params.row.id) ? 'outlined' : 'contained'
            }
            sx={{ height: '2rem' }}
          >
            {selectedGroups?.includes(params.row.id) ? 'Remove' : 'Add'}
          </Button>
        );
      },
      width: 120,
    },
  ];

  const rows = groupData?.map((group: any) => ({
    id: group.id,
    name: group.name,
    path: group.path,
  }));

  return (
    <CustomModal
      maxWidth="md"
      title="Add/Remove Group"
      open={open}
      handleClose={onClose}
    >
      <Stack width="600px" sx={{ padding: '30px 15px 10px' }}>
        <DataGrid
          rows={rows}
          columns={columns.map((col) => ({ ...col, sortable: false }))}
          components={{
            Footer: () => null,
          }}
          disableColumnMenu
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '&.MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'riPrimary.10',
              fontSize: 16,
              borderRadius: '50px',
              borderBottom: 'none',
            },
          }}
        />
      </Stack>
    </CustomModal>
  );
};

export default AddGroupsModal;
