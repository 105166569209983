import { useKeycloak } from '@react-keycloak/web';
import axios from 'axios';

import { getSessionStorage } from '../Authentication/Actions/authentication';

const BASE_URL = process.env.REACT_APP_RIVERUS_CLM_API;

export const authApi = axios.create({
  baseURL: BASE_URL,
});

const handleKeycloak = () => {
  const { keycloak } = useKeycloak();
  keycloak.logout();
};

authApi.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = getSessionStorage('accessToken');

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

authApi.interceptors.response.use(
  async (response) => {
    return response.data;
  },
  async (error) => {
    const originalRequest = error?.config;
    const errMessage = error?.response?.data?.message as string;
    if (error.response.status === 401) {
      window.location.reload();
      handleKeycloak();
    }
    if (error.response.status === 403) {
      window.location.reload();
    }
    if (errMessage?.includes('not logged in') && !originalRequest._retry) {
      originalRequest._retry = true;
      return authApi(originalRequest);
    }
    return Promise.reject(error);
  }
);

export const getProfile = async (): Promise<any> => {
  return await authApi.get(`users/profile/`);
};

export const getIPAddress = async (): Promise<any> => {
  return await axios.get('https://api.ipify.org/?format=json');
};

export const trackLogin = async (payload: any): Promise<any> => {
  return await authApi.post('users/track/', payload);
};

export const getTrackDetails = async (params = ''): Promise<any> => {
  return await authApi.get(`users/track/${params}`);
};

export const getEmailAudit = async (params = ''): Promise<any> => {
  return await authApi.get(`stylus/email-bounced/${params}`);
};

export const getSenderEmails = async () => {
  return await authApi.get(`stylus/email-bounced/user-details/`);
};
