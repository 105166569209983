import React, { useEffect } from 'react';

import { Box } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import CheckListComponent from '../DraftChecklist/CheckListComponent';

interface Props {
  draftData: any;
  isExternal?: boolean;
  templateUserIds?: any;
  getDraftCheckList?: any;
  isLoadingCheckList?: any;
}

const ChecklistTab: React.FC<Props> = ({
  draftData,
  isExternal,
  templateUserIds,
  getDraftCheckList,
  isLoadingCheckList,
}) => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (draftData) {
      queryClient.invalidateQueries({
        queryKey: ['draft_checkLists'],
      });
    }
  }, []);

  return (
    <Box
      sx={{
        marginY: '15px',
      }}
    >
      {draftData && (
        <CheckListComponent
          selectedDraftData={draftData}
          isExternal={isExternal}
          templateUserIds={templateUserIds}
          getDraftCheckList={getDraftCheckList}
          isLoadingCheckList={isLoadingCheckList}
        />
      )}
    </Box>
  );
};

export default ChecklistTab;
