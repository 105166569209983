import React, { useEffect } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import ControlledTextField from '../RiverusUI/Components/ControlledTextField';
import CustomModal from '../RiverusUI/Components/CustomModal';
import { createGroup, editGroup } from '../Services/keycloak';
import { QueryKeyGenerator } from '../Utils/QueryKeyGenerator';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  editDetails?: any;
  groupsTableKey?: any;
}

const CreateGroupsModal: React.FC<Props> = ({
  open,
  onClose,
  editDetails,
  groupsTableKey,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const methods = useForm();
  const { handleSubmit, control, setValue } = methods;

  useEffect(() => {
    if (editDetails) {
      setValue('group_name', editDetails?.name);
    }
  }, [editDetails, setValue]);

  const { mutate: createGroupMutation, isPending: loadingCreateGroup } =
    useMutation({
      mutationFn: createGroup,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            QueryKeyGenerator.getRequestApproval(),
            'keycloak_groups',
            groupsTableKey,
          ],
        });
        enqueueSnackbar('Group created successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        onClose();
      },
      onError: (error: any) => {
        const responseData = error?.response?.data?.errorMessage;
        enqueueSnackbar(`${responseData || 'Failed to create Group!'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: editGroupMutation, isPending: loadingEditGroup } =
    useMutation({
      mutationFn: editGroup,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [
            QueryKeyGenerator.getRequestApproval(),
            'keycloak_groups',
            groupsTableKey,
          ],
        });
        enqueueSnackbar('Group edited successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        onClose();
      },
      onError: (error: any) => {
        const responseData = error?.response?.data?.errorMessage;
        enqueueSnackbar(`${responseData || 'Failed to edit Group!'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const onSubmit = (data: any) => {
    if (!editDetails) {
      const payload = {
        name: data?.group_name,
      };
      createGroupMutation(payload);
    } else {
      const payload = {
        id: editDetails?.id,
        body: {
          id: editDetails?.id,
          name: data?.group_name,
          path: `/${data?.group_name}`,
          attributes: {},
          realmRoles: [],
          clientRoles: {},
          subGroups: [],
        },
      };
      editGroupMutation(payload);
    }
  };

  return (
    <CustomModal
      title={`${editDetails ? 'Edit' : 'Create'} a Group`}
      maxWidth="lg"
      open={open}
      handleClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data: any) => onSubmit(data))}>
          <Stack spacing={3} sx={{ padding: '30px 15px 15px' }}>
            <ControlledTextField
              name="group_name"
              control={control}
              required
              label="Group Name"
              fullWidth
            />
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                width="100%"
              >
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={loadingCreateGroup || loadingEditGroup}
                >
                  {editDetails ? 'Save' : 'Create'}
                </LoadingButton>
                <Button
                  variant="outlined"
                  startIcon={<CloseIcon />}
                  onClick={onClose}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateGroupsModal;
