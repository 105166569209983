import React, { useEffect, useMemo, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Chip, LinearProgress, Stack, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import FilterCardView from './CardView';
import ClauseTypeFilter from './CLauseTypeFilter';
import { scrollContainerSx } from '../../Filter/Components/StaticData';
import FilterBg from '../../RiverusUI/Components/Icons/FilterBg';
import ListSkeleton from '../../RiverusUI/Components/Skeleton/ListSkeleton';
import { getInsightsES, getSearchResult } from '../../Services/search';

const Search = () => {
  const { query } = useParams();
  const navigate = useNavigate();
  const methods = useForm();
  const { control, watch, setValue } = methods;
  const [searchData, setSearchData] = useState<any>();
  const clause_type = watch('clause_type') || [];
  const jurisdiction = watch('jurisdiction') || [];
  const contracting_parties = watch('contracting_parties') || [];
  const contract_type = watch('contract_type') || [];
  const groups = watch('groups') || [];
  const projects = watch('projects') || [];

  const searchTerm = useMemo(
    () => (query ? JSON.parse(atob(query)) : { search: [] }),
    [query]
  );

  const showSearchResult = useMemo(
    () =>
      searchTerm.search.length ||
      contract_type?.length ||
      groups?.length ||
      projects?.length ||
      jurisdiction?.length ||
      clause_type?.length ||
      contracting_parties?.length,
    [
      searchTerm,
      contract_type,
      jurisdiction,
      groups,
      projects,
      clause_type,
      contracting_parties,
    ]
  );

  const { data, isLoading, isFetching, isSuccess } = useQuery({
    queryKey: [
      'search-result',
      searchTerm,
      clause_type,
      jurisdiction,
      contracting_parties,
      contract_type,
      groups,
      projects,
    ],
    queryFn: () => {
      const clauseTypes = clause_type?.map((data: any) => data.index) || [];

      return getSearchResult({
        ...searchTerm,
        ...(clauseTypes.length > 0 && {
          indexes: clauseTypes,
        }),
        ...(groups?.length > 0 && { groups: groups }),
        ...(projects?.length > 0 && { projects: projects }),
        ...(contract_type?.length > 0 && { contract_type: contract_type }),
        ...(jurisdiction?.length > 0 && { jurisdiction: jurisdiction }),
        ...(contracting_parties?.length > 0 && {
          contracting_parties: contracting_parties,
        }),
      });
    },
    enabled: searchTerm.search.length > 0,
    retry: false,
  });

  const {
    mutate: getInsights,
    isPending: insightsLoading,
    data: insightsData,
  } = useMutation({
    mutationKey: ['insights-es', searchData, searchTerm, clause_type],
    mutationFn: () => {
      const clauseTypes = clause_type?.map((data: any) => data.index) || [];
      const ids = searchData?.map((item: any) => item.id) || [];
      const payload = {
        ...(ids.length > 0 && { ids: ids || [] }),
        ...(clauseTypes.length > 0 && {
          indexes: clauseTypes,
        }),
        ...(searchTerm.search.length > 0 && searchTerm),
      };
      return getInsightsES(payload);
    },
  });

  useEffect(() => {
    if (data && isSuccess) {
      setSearchData(data);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (searchData) {
      getInsights();
    }
  }, [searchData]);

  useEffect(() => {
    if (searchTerm.search.length === 0) {
      navigate('/documentlibrary');
    }
  }, [searchTerm]);

  const handleSearchTermDelete = (term: string) => {
    let searchQuery = searchTerm;
    const filteredData = searchQuery.search.filter(
      (item: any) => item !== term
    );
    searchQuery = {
      search: filteredData,
    };
    const url = `/search/${btoa(JSON.stringify(searchQuery))}`;
    navigate(url);
  };

  const handleFilterDelete = (list: any, item: string, clause: string) => {
    const filteredData = list.filter((data: string) => data !== item);
    setValue(clause, filteredData);
  };

  const handleClauseTypeDelete = (item: string) => {
    const filteredData = clause_type.filter((data: any) => data.index !== item);
    setValue('clause_type', filteredData);
  };

  const totalCount = useMemo(
    () => searchData?.length || 0,
    [searchData, isSuccess]
  );

  // const isExpiredContract = (end_date: string) => {
  //   const endDateObj = new Date(end_date);
  //   const currentDate = new Date();
  //   // Compare endDate with current date
  //   return end_date ? endDateObj < currentDate : false;
  // };

  if (isLoading && !searchData) {
    return <ListSkeleton numberOfLines={10} />;
  }
  return (
    <Stack direction="row" gap={2}>
      <Stack
        minWidth="25vw"
        maxWidth="28vw"
        sx={{ ...scrollContainerSx, maxHeight: '83vh', paddingRight: '6px' }}
      >
        <FormProvider {...methods}>
          <form>
            <ClauseTypeFilter
              insightsData={insightsData}
              isLoading={insightsLoading}
              control={control}
            />
          </form>
        </FormProvider>
      </Stack>

      {showSearchResult ? (
        <Stack gap={2} flex={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              <strong>{totalCount}</strong> results for
            </Typography>
          </Stack>
          {isFetching && <LinearProgress variant="indeterminate" />}
          <Stack direction="row" gap={1} flexWrap="wrap">
            {searchTerm.search.map((term: string) => (
              <Chip
                key={term}
                label={term}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                deleteIcon={<CloseIcon fontSize="small" />}
                onDelete={() => handleSearchTermDelete(term)}
                icon={<SearchOutlinedIcon fontSize="small" />}
              />
            ))}
            {contract_type?.map((item: any, index: number) => (
              <Chip
                key={`contract-type-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() =>
                  handleFilterDelete(contract_type, item, 'contract_type')
                }
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {groups?.map((item: any, index: number) => (
              <Chip
                key={`groups-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleFilterDelete(groups, item, 'groups')}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {projects?.map((item: any, index: number) => (
              <Chip
                key={`projects-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleFilterDelete(projects, item, 'projects')}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {clause_type?.map((clause: any, index: number) => (
              <Chip
                key={`clause-type-${index}`}
                label={clause.index}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() => handleClauseTypeDelete(clause.index)}
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {jurisdiction?.map((item: any, index: number) => (
              <Chip
                key={`jurisdiction-${index}`}
                label={item}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() =>
                  handleFilterDelete(jurisdiction, item, 'jurisdiction')
                }
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
            {contracting_parties?.map((contract: any, index: number) => (
              <Chip
                key={`contracting-parties-${index}`}
                label={contract}
                variant="outlined"
                color="primary"
                sx={{ fontSize: '13px' }}
                onDelete={() =>
                  handleFilterDelete(
                    contracting_parties,
                    contract,
                    'contracting_parties'
                  )
                }
                deleteIcon={<CloseIcon fontSize="small" />}
                icon={<FilterAltOutlinedIcon fontSize="small" />}
              />
            ))}
          </Stack>
          <FilterCardView searchResultIds={searchData} />
        </Stack>
      ) : (
        <Stack
          height="70vh"
          flex={1}
          alignItems="center"
          justifyContent="center"
        >
          <FilterBg />
          <Typography>{`${totalCount} results found`}</Typography>
          <Typography>Start by searching/Filtering</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default Search;
