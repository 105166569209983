import { authApi } from './authApi';

export const getSignatureType = async () => {
  return await authApi.get('stylus/signature-type/');
};

export const updateSignatureType = async (payload: any) => {
  return await authApi.patch(
    `stylus/signature-type/${payload.id}/`,
    payload.body
  );
};
