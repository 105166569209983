import axios from 'axios';

import { authApi } from './authApi';
import { getSessionStorage } from '../Authentication/Actions/authentication';

const APIPaths = {
  getSignatories:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'users/?role=Signatories&assignee_type=internal',
  updateSignatories: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/',
  getPreSignUrl:
    process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/aws/presigned-url/',
  getContractPreSignUrl:
    process.env.REACT_APP_RIVERUS_CLM_API + 'contracts/aws/presigned-url/',
};

const signAPIConfig = () => ({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const authConfig = (contentType: string) => ({
  headers: {
    Authorization: `Bearer ${getSessionStorage(`signAccessToken`)}`,
    'Content-Type': contentType,
  },
});

export const fetchSignatoriesList = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.getSignatories}`);
};

export const fetchContractPreSignUrl = async (fileInfo: any): Promise<any> => {
  return await authApi
    .post(APIPaths?.getContractPreSignUrl, { ...fileInfo })
    .then((response) => response)
    .catch((error) => {
      const data = {
        error,
        file: fileInfo,
      };
      throw data;
    });
};

export const fetchPresignedUrl = async (fileInfo: any): Promise<any> => {
  return await authApi.post(APIPaths?.getPreSignUrl, { ...fileInfo });
};

export const updateSignatoriesList = async (payload: any): Promise<any> => {
  return await authApi.patch(
    `${APIPaths?.updateSignatories}${payload.id}/`,
    payload.body
  );
};

export const updateWithSignRefreshToken = async (formData: FormData) => {
  return await axios
    .post(
      `https://api.in1.echosign.com/oauth/v2/refresh`,
      formData,
      signAPIConfig()
    )
    .then((response) => {
      return response;
    });
};

export const getAuthCredentials = async (): Promise<any> => {
  return await authApi.get(
    process.env.REACT_APP_RIVERUS_CLM_API + `adobe/auth/`
  );
};

export const getSignAccessToken = async (formData: FormData) => {
  const response = await axios.post(
    `https://api.in1.echosign.com/oauth/v2/token`,
    formData,
    signAPIConfig()
  );
  return response;
};

export const draftSignatures = async (draftId: string): Promise<any> => {
  return await authApi.get(
    process.env.REACT_APP_RIVERUS_CLM_API + `stylus/signatures/${draftId}/`
  );
};

export const onSignedStatus = async (
  draftId: string,
  status: string
): Promise<any> => {
  return await authApi.patch(
    process.env.REACT_APP_RIVERUS_CLM_API + `stylus/draft/${draftId}/`,
    {
      status: status,
    }
  );
};

export const getSignatureDetails = async (
  draftId: string,
  agreementIdDetails: string
): Promise<any> => {
  return await authApi.get(
    process.env.REACT_APP_RIVERUS_CLM_API +
      `stylus/draft/signature-details/?draft=${draftId}&agreement=${agreementIdDetails}`
  );
};

export const getDraft = async (draftUrl: string): Promise<any> => {
  return await fetch(draftUrl, {
    method: 'GET',
    mode: 'cors',
  })
    .then((response) => response.blob())
    .then((blob) => {
      return blob;
    });
};

export const uploadDocument = async (formData: FormData): Promise<any> => {
  return await axios.post(
    'https://api.in1.adobesign.com/api/rest/v6/transientDocuments',
    formData,
    authConfig('multipart/form-data')
  );
};

export const sendAgreement = async (agreementInfo: any): Promise<any> => {
  const response = await axios.post(
    'https://api.in1.adobesign.com/api/rest/v6/agreements',
    agreementInfo,
    authConfig('application/json')
  );
  return response;
};

export const updateDraftSignatures = async (
  agreementId: string,
  draftId: string
): Promise<any> => {
  return await authApi.post(
    process.env.REACT_APP_RIVERUS_CLM_API + `stylus/signatures/`,
    {
      agreement: agreementId,
      draft: draftId,
    }
  );
};

export const updateSignatureDetails = async (payload: any): Promise<any> => {
  return await authApi.post(
    process.env.REACT_APP_RIVERUS_CLM_API +
      `stylus/signatures/create-signature-details/`,
    payload
  );
};

export const contractSupportDocs = async (payload: any) => {
  return await authApi.post('contracts/support-documents-contract/', payload);
};

export const reInitiateSignatureMail = async (payload: any) => {
  return await authApi.post('stylus/draft/re-initiate-email/', payload);
};

export const addTempSignatories = async (payload: any) => {
  return await authApi?.post('stylus/temp-signatories/', payload);
};

export const deleteTempSignatories = async (id: string) => {
  return await authApi.delete(`stylus/temp-signatories/${id}/`);
};

export const fetchTempSignatories = async (params = ''): Promise<any> => {
  return await authApi.get(`stylus/temp-signatories/${params}`);
};

export const updateSignatoryTrackStatus = async (payload: any) => {
  return await authApi?.patch(
    `stylus/email-bounced/${payload?.id}/`,
    payload?.data
  );
};
