import axios from 'axios';

import { authApi } from './authApi';
import { PresignedDataField } from '../Draft/State/DraftState';

const baseUrl = process.env.REACT_APP_RIVERUS_CLM_API;

const axiosConfig = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
  },
};

const APIPaths = {
  groupUrl: baseUrl + 'users/groups/?type=all',
  presignedUrl: baseUrl + 'contracts/aws/presigned-url/',
  projectUrl: baseUrl + 'umbrella/projects/',
  baseContractUrl: baseUrl + 'contracts/',
  reminderUrl: baseUrl + 'reminder/',
};

export const fetchContractById = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths?.baseContractUrl}${id}/`);
};

export const fetchDownloadUrl = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths?.baseContractUrl}download/${id}/`);
};

export const fetchGroups = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.groupUrl}`);
};

export const fetchReminder = async (id: string): Promise<any> => {
  return await authApi.get(
    `${APIPaths.reminderUrl}?contract=${id}&page_size=100`
  );
};

export const fetchReminderChecklist = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.reminderUrl}?checklist=${id}`);
};

export const uploadDoc = async (payload: any) => {
  return await authApi.post(APIPaths?.presignedUrl, payload);
};

export const createReminder = async (payload: any) => {
  return await authApi.post(APIPaths?.reminderUrl, payload);
};

export const remindersDraft = async (payload: any) => {
  return await authApi.post(
    `${APIPaths?.reminderUrl}reminders-draft/`,
    payload
  );
};

export const upload_file_in_s3_bucket = async ({
  presignedPostData,
  file,
  onHandleFileProgress,
}: {
  presignedPostData: any;
  file?: any;
  onHandleFileProgress?: any;
}) => {
  const { fields, url } = presignedPostData;
  const formData = new FormData();
  Object.keys(fields).forEach((key) => {
    formData.append(key, fields[key as keyof PresignedDataField]);
  });

  file && formData.append('file', file);

  const config = onHandleFileProgress
    ? { axiosConfig, ...onHandleFileProgress }
    : axiosConfig;

  return await axios.post(url, formData, config).then(() => presignedPostData);
};

export const deleteContractDoc = async (id: string) => {
  return await authApi.delete(`${APIPaths?.baseContractUrl}${id}/`);
};

export const deleteReminder = async (id: string) => {
  return await authApi.delete(`${APIPaths?.reminderUrl}${id}/`);
};

export const deleteReminderCard = async (payload: any) => {
  return await authApi.post(
    `${APIPaths?.reminderUrl}reminder-delete/`,
    payload
  );
};

export const deleteComment = async (id: string) => {
  return await authApi.delete(
    `${APIPaths?.reminderUrl}reminder-comments/${id}/`
  );
};

export const bulkDeleteDocs = async (ids: any) => {
  const data = { id: ids };
  return await authApi.delete(`${APIPaths?.baseContractUrl}bulk-delete/`, {
    data,
  });
};

export const fetchHeaderCounts = async (): Promise<any> => {
  return await authApi.get(
    `${APIPaths?.baseContractUrl}statistics/?type=documentlibrary`
  );
};

export const fetchProjects = async (): Promise<any> => {
  return await authApi.get(APIPaths?.projectUrl);
};

export const fetchDocumentLibrary = async (payload: any) => {
  return await authApi.post(
    `${APIPaths?.baseContractUrl}document-library/&page=${payload?.pageNumber}&${payload?.params}`,
    payload?.body
  );
};

export const editReplaceTags = async (payload: any) => {
  return await authApi.patch(`${APIPaths?.baseContractUrl}tag/`, payload);
};

export const editReminder = async (payload: any) => {
  const { id, body } = payload;
  return await authApi.patch(`${APIPaths?.reminderUrl}${id}/`, body);
};

export const editContract = async (payload: any) => {
  const { id, body } = payload;
  return await authApi.patch(`${APIPaths?.baseContractUrl}${id}/`, body);
};

export const addProject = async (payload: any) => {
  return await authApi.post(APIPaths?.projectUrl, payload);
};

export const fetchAllContracts = async (): Promise<any> => {
  return await authApi.get(`${APIPaths?.baseContractUrl}document-library/`);
};

export const fetchDocLibContracts = async (params: string): Promise<any> => {
  return await authApi.get(
    `${APIPaths?.baseContractUrl}document-library/${params}`
  );
};

export const fetchContractingParty = async (id: string): Promise<any> => {
  return await authApi.get(
    `${APIPaths?.baseContractUrl}insights/${id}/contracting_parties/`
  );
};

export const updateContractingParty = async (payload: any) => {
  const { id, body } = payload;
  return await authApi.patch(
    `${APIPaths?.baseContractUrl}insights/${id}/contracting_parties/`,
    body
  );
};

export const updateEsClauseDataByType = async (payload: any) => {
  return await authApi.post('contracts/update-es/', payload);
};

export const getAllFilterInsights = async (
  contract_id: string,
  user_id: string
): Promise<any> => {
  return await authApi.get(
    `${APIPaths?.baseContractUrl}kdp-user-status/?contract=${contract_id}&user=${user_id}`
  );
};

export const filterInsights = async (payload: any) => {
  const { contract_id, user_id, body } = payload;
  return await authApi.post(
    `${APIPaths?.baseContractUrl}kdp-user-status/?contract=${contract_id}&user=${user_id}`,
    body
  );
};

export const updatedFilterInsights = async (payload: any) => {
  const { id, body } = payload;
  return await authApi.patch(
    `${APIPaths?.baseContractUrl}kdp-user-status/${id}/`,
    body
  );
};

export const getTags = async (type: string): Promise<any> => {
  return await authApi.get(`${APIPaths?.baseContractUrl}event/?type=${type}`);
};

export const AddTag = async (payload: any) => {
  return await authApi.post(`${APIPaths?.baseContractUrl}event/`, payload);
};

export const getContractTypes = async (): Promise<any> => {
  return await authApi.get('admin/contracttype/contract-filter/');
};

export const addCoOwner = async (payload: any) => {
  return await authApi.patch(`contracts/${payload?.id}/`, payload?.body);
};

export const terminateContract = async (payload: any) => {
  return await authApi.patch('contracts/terminate/', payload);
};

export const exportContractExcel = async (params = ''): Promise<any> => {
  return await authApi.get(`contracts/export-contracts-email/${params}`);
};
