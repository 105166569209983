import React, { useEffect, useMemo, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShareIcon from '@mui/icons-material/Share';
import TagIcon from '@mui/icons-material/Tag';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';

import { contractTabLabel, contractTableTabLabel } from './DocLibStaticData';
import { useDocumentLibrary } from './DocumentLibraryProvider';
// import DocumentLibraryExcel from './DocumnetLibraryExcel';
import ShareModal from './ShareModal/ShareModal';
import AddCoOwner from './TableComponents/AddCoOwner';
import DocumentLibraryTable from './TableComponents/DocumentLibraryTable';
import ReplaceAndEditTag from './TableComponents/ReplaceAndEditTag';
import UploadContractDoc from './TableComponents/UploadContractDoc';
import UploadSupportFile from './TableComponents/UploadSupportFile';
import { useUserData } from '../../App/Component/UserDataProvider';
import { getKeyCloakRealmFromLS } from '../../Authentication/Actions/authentication';
import DateRangeModel from '../../Draft/Component/DateRangeModel';
import StaticTab from '../../Draft/Component/TableTabList';
import { a11yProps } from '../../RiverusUI/Components/CustomTabPanel';
import ReusableConfirmationModal from '../../RiverusUI/Components/ReusableConfirmationModal';
import {
  bulkDeleteDocs,
  exportContractExcel,
  fetchDocLibContracts,
  fetchHeaderCounts,
} from '../../Services/DocumentLibrary';

const initialFilter = {
  projects: null,
  title: null,
  groups: null,
  contract_type: null,
  contracting_parties: null,
  status: null,
};

const DocumentLibraryComponent: React.FC = () => {
  const {
    activeTab,
    handleActiveTab,
    activeHeader,
    setActiveHeader,
    filters,
    setFilters,
    sorting,
    setSorting,
  } = useDocumentLibrary();

  const [openUploadContract, setOpenUploadContract] = useState<boolean>(false);
  const [openUploadSupportDoc, setOpenUploadSupportDoc] =
    useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<GridRowId[]>([]);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openReplaceModal, setOpenReplaceModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openShareModal, setOpenShareModal] = useState<boolean>(false);
  const [openCoOwnerModal, setOpenCoOwnerModal] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isOpenDateRange, setIsOpenDateRange] = useState<boolean>(false);
  const [dateRangeParams, setDateRangeParams] = useState<any>({
    start: null,
    end: null,
  });
  const [refreshKey, setRefreshKey] = useState(false);

  const queryClient = useQueryClient();

  const realm_name = getKeyCloakRealmFromLS();

  const { enqueueSnackbar } = useSnackbar();
  const { user_data, user_id, userIsAdmin } = useUserData();

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    return () => {
      handleActiveTab('active');
      setActiveHeader('active');
      setFilters({
        end_date: null,
        projects: null,
        title: null,
        groups: null,
        contract_type: null,
        contracting_parties: null,
        status: null,
      });
      setSorting([]);
    };
  }, []);

  const { data: headerData } = useQuery({
    queryKey: ['contract_header_count'],
    queryFn: async () => {
      const response = await fetchHeaderCounts();
      return response?.contracts_statistic;
    },
  });

  const {
    data: contractTableData,
    isLoading: isLoadingTable,
    isError,
  } = useQuery({
    queryKey: [
      'contract_table_data',
      filters,
      activeTab,
      activeHeader,
      pageNumber,
      sorting,
      dateRangeParams,
    ],
    queryFn: async () => {
      let params = '';

      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = `${filterParam}&${key}=${filters[key]}`;
        }
      }

      let filterTypeParam = '';
      if (activeHeader && activeHeader !== 'contracts_count') {
        if (
          activeHeader === 'terminated' ||
          activeHeader === 'active' ||
          activeHeader === 'expired'
        ) {
          filterTypeParam = '';
        } else {
          filterTypeParam = `&${activeHeader}=true`;
        }
      }

      let sortingParam = '';

      if (sorting?.length > 0) {
        for (let i = 0; i < sorting?.length; i++) {
          if (sorting[i].sort === 'asc') {
            sortingParam = `${sortingParam}?ordering=${sorting[i].field}`;
          } else {
            sortingParam = `${sortingParam}?ordering=-${sorting[i].field}`;
          }
        }
      } else {
        sortingParam = '?ordering=-created_on';
      }

      if (activeTab === 'all') {
        params = `${sortingParam}&page=${
          pageNumber + 1
        }${filterParam}${filterTypeParam}`;
      } else if (activeTab === 'active') {
        params = `${sortingParam}&page=${
          pageNumber + 1
        }&active=true${filterParam}${filterTypeParam}`;
      } else if (activeTab === 'expired') {
        params = `${sortingParam}&page=${
          pageNumber + 1
        }&expired=true${filterParam}${filterTypeParam}`;
      } else if (activeTab === 'my_contracts') {
        params = `${sortingParam}&page=${
          pageNumber + 1
        }&created_by=${realm_name}__${user_data?.email}`;
      } else if (activeTab === 'terminate') {
        params = `${sortingParam}&page=${
          pageNumber + 1
        }&terminate=true${filterParam}${filterTypeParam}`;
      }

      let dateParams = '';
      if (dateRangeParams?.start && dateRangeParams?.end) {
        dateParams = `&start_date=${dateRangeParams?.start}&end_date=${dateRangeParams?.end}`;
      }

      params = `${params}${dateParams}`;

      const response = await fetchDocLibContracts(params);
      return response;
    },
    enabled: activeHeader !== 'total_contracts_size',
    retry: false,
  });

  const { mutate: sendExcel, isPending: excelSending } = useMutation({
    mutationKey: ['send-contract-excel'],
    mutationFn: () => {
      let filterParam = '';
      for (const key in filters) {
        if (filters[key]) {
          filterParam = filterParam
            ? `${filterParam}&${key}=${filters[key]}`
            : `${key}=${filters[key]}`;
        }
      }

      let filterTypeParam = '';
      if (
        (activeHeader && activeHeader !== 'contracts_count') ||
        activeTab === 'my_contracts'
      ) {
        if (activeTab === 'my_contracts') {
          filterTypeParam = `created_by=email`;
        } else if (activeHeader === 'terminated') {
          filterTypeParam = `filter_type=terminate`;
        } else {
          filterTypeParam = `filter_type=${activeHeader}`;
        }
      }

      let dateParams = '';
      if (dateRangeParams?.start && dateRangeParams?.end) {
        dateParams = `start_date=${dateRangeParams?.start}&end_date=${dateRangeParams?.end}`;
      }

      let params = '';

      if (dateParams && filterTypeParam && filterParam) {
        params = `?${dateParams}&${filterTypeParam}&${filterParam}`;
      } else if (dateParams && filterTypeParam) {
        params = `?${dateParams}&${filterTypeParam}`;
      } else if (dateParams && filterParam) {
        params = `?${dateParams}&${filterParam}`;
      } else if (filterTypeParam && filterParam) {
        params = `?${filterTypeParam}&${filterParam}`;
      } else if (dateParams) {
        params = `?${dateParams}`;
      } else if (filterTypeParam) {
        params = `?${filterTypeParam}`;
      } else if (filterParam) {
        params = `?${filterParam}`;
      }

      return exportContractExcel(params);
    },
    onSuccess: () => {
      enqueueSnackbar('The report will be delivered to your inbox shortly', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(
        'Oops... something went wrong. Please reload the page to try again!',
        {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  }, [isError]);

  const handleCloseUploadContract = () => {
    setOpenUploadContract(false);
  };

  const handleCloseSupportDoc = () => {
    setOpenUploadSupportDoc(false);
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };

  const handleCloseReplaceModal = () => {
    setOpenReplaceModal(false);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setPageNumber(0);
    handleActiveTab(newValue);
  };

  const handleRefresh = () => {
    if (JSON.stringify(filters) === JSON.stringify(initialFilter)) {
      queryClient.invalidateQueries({ queryKey: ['contract_table_data'] });
    } else {
      setFilters(initialFilter);
    }
    queryClient.invalidateQueries({ queryKey: ['contract_header_count'] });
    setRefreshKey((prevKey) => !prevKey);
  };

  const { mutate: deleteBulkUploadedDocs, isPending: loadingDelete } =
    useMutation({
      mutationKey: ['delete_bulk_uploaded_docs'],
      mutationFn: bulkDeleteDocs,
      onSuccess: () => {
        enqueueSnackbar('Documents deleted successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
        queryClient.invalidateQueries({ queryKey: ['contract_header_count'] });
        queryClient.invalidateQueries({ queryKey: ['contract_table_data'] });
        handleCloseDeleteModal();
      },
      onError: () => {
        enqueueSnackbar('Failed to delete all documents!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const userCanDoAction = useMemo(() => {
    const filterContracts = contractTableData?.results?.filter(
      (contractData: any) => selectedRow?.includes(contractData?.id)
    );

    const userIsContractCreator = filterContracts?.every(
      (item: any) => item?.creator_details?.id === user_id
    );

    const userIsOwner = filterContracts?.every((item: any) =>
      item?.owners?.some((ownerItem: any) => ownerItem?.id === user_id)
    );

    if (userIsContractCreator || userIsOwner || userIsAdmin) {
      return true;
    }
    return false;
  }, [contractTabLabel, selectedRow, userIsAdmin, user_id]);

  const handleOpenSupportModal = () => {
    if (selectedRow?.length) {
      setOpenUploadSupportDoc(true);
    } else {
      enqueueSnackbar('Please select any contract', {
        variant: 'info',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    }
  };

  const handleOpenEdit = () => {
    if (userCanDoAction) {
      setOpenEditModal(true);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleOpenReplaceModal = () => {
    if (userCanDoAction) {
      setOpenReplaceModal(true);
    } else {
      if (selectedRow?.length === 1) {
        enqueueSnackbar(
          'You are not the owner of the contract. Owner or Admin can perform this action',
          {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      } else {
        enqueueSnackbar(
          'You are not the owner for all the contracts, please bulk select contracts where you are the owner for all.',
          {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      }
    }
  };

  const handleOpenShareModal = () => {
    if (userCanDoAction) {
      setOpenShareModal(true);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleOpenDeleteModal = () => {
    if (userCanDoAction) {
      setOpenDeleteModal(true);
    } else {
      if (selectedRow?.length === 1) {
        enqueueSnackbar(
          'You are not the owner of the contract. Owner or Admin can perform this action',
          {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      } else {
        enqueueSnackbar(
          'You are not the owner for all the contracts, please bulk select contracts where you are the owner for all.',
          {
            variant: 'info',
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }
        );
      }
    }
  };

  const handleOpenCoOwnerModal = () => {
    if (userCanDoAction) {
      setOpenCoOwnerModal(true);
    } else {
      enqueueSnackbar(
        'You are not the owner of the contract. Owner or Admin can perform this action',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  };

  const handleOpenUploadContract = () => {
    setOpenUploadContract(true);
  };

  const orderedKeys = [
    'contracts_count',
    'total_contracts_size',
    'active',
    'expiring_this_quarter',
    'expiring',
    'expired',
  ];

  return (
    <Stack>
      <Stack className="sidebar-right-column" gap="16px">
        <Stack spacing="16px" direction="column">
          <Stack
            direction="row"
            spacing="60px"
            sx={{
              width: '100%',
              overflowX: 'scroll',
              scrollbarWidth: 'none',
            }}
          >
            <Stack direction="column" spacing="10px">
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                Upload
              </Typography>
              <Stack direction="row" spacing="15px">
                <Button
                  sx={{
                    color: 'white',
                    backgroundColor: 'riPrimary.500',
                    padding: '8px 16px',
                    borderRadius: '6px',
                    fontSize: '11px',
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: 1,
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={handleOpenUploadContract}
                >
                  <UploadFileIcon fontSize="small" />
                  <br />
                  {selectedRow?.length === 1
                    ? 'Amendment Contract'
                    : 'Contract'}
                </Button>
                <Button
                  sx={{
                    color: 'riPrimary.500',
                    padding: '8px 16px',
                    borderRadius: '6px',
                    fontSize: '11px',
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    lineHeight: 1,
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                  }}
                  variant="outlined"
                  onClick={handleOpenSupportModal}
                >
                  <FileUploadOutlinedIcon fontSize="small" />
                  <br />
                  Support document
                </Button>
              </Stack>
            </Stack>
            <Stack direction="column" spacing="10px">
              <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                All Contracts
              </Typography>
              <Tabs
                aria-label="all contracts tabs"
                variant="scrollable"
                scrollButtons={false}
                sx={{ marginTop: '4px' }}
                value={activeHeader}
                onChange={handleTabChange}
              >
                {headerData &&
                  orderedKeys.map((key, index) => (
                    <StaticTab
                      key={index}
                      count={`${headerData[key]} ${
                        contractTabLabel[key] === 'Used space' ? 'MB' : ''
                      }`}
                      label={contractTabLabel[key]}
                      value={key}
                      {...a11yProps(key)}
                    />
                  ))}
              </Tabs>
            </Stack>
          </Stack>
          {selectedRow?.length > 0 ? (
            <Stack direction="row" justifyContent="end" alignItems="center">
              {selectedRow?.length === 1 && (
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleOpenCoOwnerModal}
                >
                  Add Co-Owner
                </Button>
              )}
              {selectedRow?.length === 1 ? (
                <Button startIcon={<TagIcon />} onClick={handleOpenEdit}>
                  Add / Edit tags
                </Button>
              ) : (
                <Button
                  startIcon={<TagIcon />}
                  onClick={handleOpenReplaceModal}
                >
                  Add / Replace tags
                </Button>
              )}
              {selectedRow?.length === 1 && (
                <Button
                  startIcon={<ShareIcon />}
                  onClick={handleOpenShareModal}
                >
                  Share
                </Button>
              )}
              <Button
                startIcon={<DeleteOutlineIcon />}
                onClick={handleOpenDeleteModal}
              >
                Delete
              </Button>
            </Stack>
          ) : (
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '10px' }}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Tabs
                  aria-label="All contracts tabs"
                  value={activeTab}
                  onChange={handleTabChange}
                >
                  {Object.entries(contractTableTabLabel)?.map(
                    ([key], index) => (
                      <Tab
                        key={index}
                        label={contractTableTabLabel[key]}
                        value={key}
                        {...a11yProps(key)}
                      />
                    )
                  )}
                </Tabs>
                <Stack direction="row">
                  <LoadingButton
                    onClick={() => setIsOpenDateRange(true)}
                    startIcon={
                      dateRangeParams?.start ? <CloseIcon /> : <DateRangeIcon />
                    }
                  >
                    {dateRangeParams?.start
                      ? 'clear date filter'
                      : 'Filter By Date'}
                  </LoadingButton>
                  <LoadingButton
                    loading={excelSending}
                    disabled={
                      isLoadingTable || !contractTableData?.results?.length
                    }
                    onClick={() => sendExcel()}
                    startIcon={<ForwardToInboxIcon />}
                  >
                    Email Excel
                  </LoadingButton>
                  <IconButton onClick={handleRefresh}>
                    <RefreshIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </Box>
          )}
          <DocumentLibraryTable
            tableData={contractTableData}
            pageNumber={pageNumber}
            setPageNumberChange={setPageNumber}
            setSelectedRow={setSelectedRow}
            setFilters={setFilters}
            filters={filters}
            isLoading={isLoadingTable}
            setSorting={setSorting}
            refreshKey={refreshKey}
          />
        </Stack>
      </Stack>

      {openUploadContract && (
        <UploadContractDoc
          open={openUploadContract}
          onClose={handleCloseUploadContract}
          handleRefresh={handleRefresh}
          selectedRow={selectedRow}
        />
      )}

      {openUploadSupportDoc && (
        <UploadSupportFile
          open={openUploadSupportDoc}
          onClose={handleCloseSupportDoc}
          handleRefresh={handleRefresh}
          selectedRow={selectedRow}
          allContracts={contractTableData?.results || []}
        />
      )}

      {openCoOwnerModal && (
        <AddCoOwner
          open={openCoOwnerModal}
          onClose={() => setOpenCoOwnerModal(false)}
          contractId={selectedRow}
        />
      )}

      {openEditModal && (
        <ReplaceAndEditTag
          open={openEditModal}
          onClose={handleCloseEditModal}
          selectedIds={selectedRow}
          replace={false}
          title="Add / Edit Tags"
        />
      )}

      {openReplaceModal && (
        <ReplaceAndEditTag
          open={openReplaceModal}
          onClose={handleCloseReplaceModal}
          selectedIds={selectedRow}
          replace={false}
          title="Add / Replace Tags"
        />
      )}

      {openShareModal && (
        <ShareModal
          openModal={openShareModal}
          handleCloseShareBtn={() => setOpenShareModal(false)}
          selectedList={selectedRow}
          type="umbrella"
        />
      )}

      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          title="Delete Document"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => deleteBulkUploadedDocs(selectedRow)}
          buttonLoading={loadingDelete}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected document will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
      {isOpenDateRange && (
        <DateRangeModel
          open={isOpenDateRange}
          onClose={() => setIsOpenDateRange(false)}
          title="Contract upload date range"
          setDateRangeParams={setDateRangeParams}
          dateRange={dateRangeParams}
        />
      )}
    </Stack>
  );
};

export default DocumentLibraryComponent;
