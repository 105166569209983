import React, { useMemo, useState } from 'react';

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

import CreateRolesModal from './CreateRolesModal';
import RolesTable from './RolesTable';
import { useUserData } from '../App/Component/UserDataProvider';
import { IObject } from '../interfaces/IObject';
import ReusableConfirmationModal from '../RiverusUI/Components/ReusableConfirmationModal';
import { deleteRole, fetchKeycloakRoles } from '../Services/keycloak';
import { QueryKeyGenerator } from '../Utils/QueryKeyGenerator';

const RolesComponent = () => {
  const [selectedRow, setSelectedRow] = useState<GridRowId[]>([]);
  const [pageNumber, setPageNumberChange] = useState<number>(0);
  const [filters, setFilters] = useState<IObject>({
    roles: null,
    description: null,
    actions: null,
  });
  const [openCreateRole, setOpenCreateRole] = useState<boolean>(false);
  const [rolesTableKey, setRolesTableKey] = useState<number>(0);

  const [editDetails, setEditDetails] = useState<any>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [deleteDocId, setDeleteDocId] = useState<any>('');

  const { userIsAdmin } = useUserData();

  const handleClose = () => {
    setEditDetails(false);
    setOpenCreateRole(false);
  };

  const queryClient = useQueryClient();

  const handleCloseDeleteConfirmation = () => {
    setDeleteDocId('');
    setOpenDeleteModal(false);
  };

  const { mutate: deleteRoleMutation } = useMutation({
    mutationFn: deleteRole,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          QueryKeyGenerator.getRequestApproval(),
          'keycloak_roles',
          rolesTableKey,
        ],
      });
      handleCloseDeleteConfirmation();
      enqueueSnackbar('Deleted role successfully!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const {
    data: rolesData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [
      QueryKeyGenerator.getRequestApproval(),
      'keycloak_roles',
      rolesTableKey,
    ],
    queryFn: fetchKeycloakRoles,
  });

  const handleDelete = React.useCallback(
    (ids: GridRowId[]) => {
      if (ids.length > 0) {
        setOpenDeleteModal(true);
        setDeleteDocId(ids);
      }
    },
    [deleteRoleMutation]
  );

  const handleEdit = (data: any) => {
    setEditDetails(data);
    setOpenCreateRole(true);
  };

  const filteredRolesData = useMemo(() => {
    return Array.isArray(rolesData)
      ? rolesData?.filter(
          (role: any) =>
            role?.name !== 'uma_authorization' &&
            role?.name !== 'offline_access' &&
            !role?.name?.startsWith('default-roles-')
        )
      : [];
  }, [rolesData]);

  const renderTable = React.useCallback(
    () => (
      <RolesTable
        key={rolesTableKey}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        data={filteredRolesData}
        isLoading={isLoading || isFetching}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        setFilters={setFilters}
        deleteRole={handleDelete}
        editRole={handleEdit}
        filters={filters}
        editDetails={editDetails}
      />
    ),
    [
      rolesData,
      handleDelete,
      isFetching,
      isLoading,
      pageNumber,
      selectedRow,
      rolesTableKey,
    ]
  );

  return (
    <Stack>
      <Stack className="sidebar-right-column" gap="16px">
        <Stack gap="16px">
          <Button
            sx={{
              color: 'white',
              backgroundColor: 'riPrimary.500',
              padding: '8px 16px',
              borderRadius: '6px',
              fontSize: '11px',
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'column',
              lineHeight: 1,
              alignItems: 'center',
              margin: '0',
              '&:hover': {
                backgroundColor: 'riPrimary.500',
              },
              '&:disabled': {
                border: '2px solid rgba(0, 0, 0, 0.26)',
                background: 'unset',
              },
            }}
            onClick={() => {
              setOpenCreateRole(true);
            }}
            disabled={!userIsAdmin}
          >
            <AddCircleOutlineRoundedIcon fontSize="small" />
            <br />
            New Role
          </Button>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: '10px' }}>
              <IconButton onClick={() => setRolesTableKey(Math.random())}>
                <RefreshIcon />
              </IconButton>
            </Box>
            {renderTable()}
          </Box>
        </Stack>
      </Stack>
      {openCreateRole && (
        <CreateRolesModal
          open={openCreateRole}
          onClose={handleClose}
          rolesTableKey={rolesTableKey}
          editDetails={editDetails}
        />
      )}
      {openDeleteModal && (
        <ReusableConfirmationModal
          open={openDeleteModal}
          onClose={handleCloseDeleteConfirmation}
          title="Delete Role"
          cancelBtnText="No, Go Back"
          confirmBtnText="Yes, Delete"
          onConfirm={() => deleteRoleMutation(deleteDocId)}
        >
          <Stack spacing={2}>
            <Typography>Are you sure?</Typography>
            <Typography>
              The selected role will be removed permanently.
            </Typography>
            <Typography>Note: This action is not reversible.</Typography>
          </Stack>
        </ReusableConfirmationModal>
      )}
    </Stack>
  );
};

export default RolesComponent;
