import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import PdfView from '../../../Draft/Component/PdfView';
import ListSkeleton from '../../../RiverusUI/Components/Skeleton/ListSkeleton';
import { fetchDownloadUrl } from '../../../Services/DocumentLibrary';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  documentId?: string;
  documentLink?: string;
}

const ViewDocumentDrawer: React.FC<Props> = ({
  open,
  onClose,
  documentId = '',
  documentLink,
}) => {
  const [accessUrl, setAccessUrl] = useState<string>('');
  const [instance, setInstance] = useState<any>();

  const { data: viewDocumentData, isLoading: viewLoading } = useQuery({
    queryKey: ['get_access_url', documentId],
    queryFn: async () => {
      const response: any = await fetchDownloadUrl(documentId);
      setAccessUrl(response?.location);
      return response;
    },
    enabled: !!documentId,
  });
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': {
          width: '960px',
        },
      }}
    >
      {viewLoading ? (
        <Box px={2}>
          <ListSkeleton />
        </Box>
      ) : (
        <Stack spacing={2} width="900px" marginX="15px">
          <Box display="flex" alignItems="center" width="900px">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
            <Typography>View Document</Typography>
          </Box>
          <PdfView
            draftData={viewDocumentData}
            fileLink={documentLink ? documentLink : accessUrl}
            instance={instance}
            setInstance={setInstance}
          />
        </Stack>
      )}
    </Drawer>
  );
};

export default ViewDocumentDrawer;
