import React, { useMemo, useState } from 'react';

import CallMadeIcon from '@mui/icons-material/CallMade';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import TabIcon from '@mui/icons-material/Tab';
import {
  Button,
  Chip,
  IconButton,
  Popover,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import * as changesets from 'json-diff-ts';

import ConnectedDocsDrawer from './ConnectedDocsDrawer';
import { useUserData } from '../../../App/Component/UserDataProvider';
import { fetchContractById } from '../../../Services/DocumentLibrary';

interface Props {
  contractData: any;
}

const TableTooltip: React.FC<Props> = ({ contractData }) => {
  const [contractDetails, setContractDetails] = useState<any>({});
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const { user_id, userIsAdmin } = useUserData();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setContractDetails({});
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const { data: contract_data, isLoading: contractLoading } = useQuery({
    queryKey: ['get_contract_data_id', contractDetails?.id],
    queryFn: async () => {
      const response: any = await fetchContractById(contractDetails?.id);
      return response;
    },
    enabled: !!contractDetails?.id,
  });

  const { data: refContractData } = useQuery({
    queryKey: ['get_ref_contracts', contractDetails?.id],
    queryFn: async () => {
      const response: any = await fetchContractById(
        contract_data?.refcontract_id
      );
      return response;
    },
    enabled: !!contract_data?.refcontract_id,
  });

  const contracting_parties = useMemo(() => {
    let changeList;
    if (contractData?.contracting_parties?.length > 0) {
      const diff = JSON.parse(
        JSON.stringify(contractData?.contracting_parties?.[0]?.edited_content)
      );

      const orig = JSON.parse(
        JSON.stringify(contractData?.contracting_parties?.[0]?.raw_content)
      );

      if (diff) {
        const unFlattenChanges = changesets.unflattenChanges(diff.update);
        changeList = JSON.parse(
          JSON.stringify(
            changesets.applyChangeset(
              JSON.parse(JSON.stringify(orig)),
              unFlattenChanges
            )
          )
        );
      } else {
        changeList = JSON.parse(
          JSON.stringify(
            changesets.applyChangeset(JSON.parse(JSON.stringify(orig)), diff)
          )
        );
      }
      return changeList.contracting_parties;
    }
    return changeList;
  }, [contractData?.contracting_parties]);

  const isOwner = useMemo(
    () => contractData?.creator_details?.id === user_id,
    [contractData?.creator_details, user_id]
  );

  const userIsCoOwner = useMemo(
    () => contractData?.owners?.find((owner: any) => owner?.id === user_id),
    [contractData?.owners, user_id]
  );

  const userCanDoAction = useMemo(
    () => isOwner || userIsAdmin || userIsCoOwner,
    [isOwner, userIsAdmin, userIsCoOwner]
  );

  const handleOpenContract = (contractId: string) => {
    window.open('/document/documentlibrary/' + btoa(contractId), '_blank');
  };

  const isExpiredContract = useMemo(() => {
    const endDateObj = new Date(contractData?.end_date);
    const currentDate = new Date();
    // Compare endDate with current date
    return contractData?.end_date ? endDateObj < currentDate : false;
  }, [contractData]);

  return (
    <>
      <IconButton
        onClick={(event) => {
          handleClick(event);
          setContractDetails(contractData);
        }}
        aria-describedby="table_popover"
      >
        <CallMadeIcon style={{ color: '#6D264C' }} />
      </IconButton>

      <Popover
        id="table_popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ width: '80%', height: '80%' }}
      >
        <Stack direction="column" spacing={1} paddingTop="20px">
          <Stack direction="column" spacing={1} padding="0 20px">
            <Stack direction="row" spacing={1}>
              <Typography fontSize="15px" fontWeight={600}>
                Title:
              </Typography>
              <Typography fontSize="15px">{contractDetails?.title}</Typography>
              {contractData && !contractData?.terminate_status && (
                <>
                  {isExpiredContract ? (
                    <Chip
                      label="Expired"
                      sx={{ background: '#ff4d4f', color: 'white' }}
                    />
                  ) : (
                    <Chip
                      label="Active"
                      sx={{
                        background: '#87d068',
                        color: 'white',
                      }}
                    />
                  )}
                </>
              )}
              {contractData?.terminate_status && <Chip label="Terminated" />}
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography fontSize="15px" fontWeight={600}>
                Nature:
              </Typography>
              {contractDetails?.contract_type?.map(
                (item: any, index: number) => (
                  <Typography key={index} fontSize="15px">
                    {item?.name}
                  </Typography>
                )
              )}
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography fontSize="15px" fontWeight={600}>
                Expiry:
              </Typography>
              <Typography fontSize="15px">
                {contractDetails?.end_date || 'N/A'}
              </Typography>
            </Stack>

            {contracting_parties?.length > 0 && (
              <Stack direction="column" spacing={1} pt="15px">
                <Typography fontSize="15px" fontWeight={600}>
                  Contracting Parties
                </Typography>
                {contracting_parties?.map((item: any, index: number) => (
                  <Typography key={index} fontSize="15px">
                    {index + 1}). {item?.party ? item?.party : item?.partyName}
                  </Typography>
                ))}
              </Stack>
            )}

            {(contract_data?.support_document?.length > 0 ||
              contract_data?.amendment?.length > 0 ||
              contract_data?.uploaded_type === 'Amendment') && (
              <Stack direction="column" spacing={1} pt="15px">
                <Typography fontSize="15px" fontWeight={600}>
                  Connected Documents
                </Typography>
                {contract_data?.uploaded_type === 'Amendment' &&
                  refContractData && (
                    <Stack spacing={2} direction="row">
                      <Typography fontSize="15px">
                        1). {refContractData?.file_name}
                      </Typography>
                      <Chip label="Master" />
                    </Stack>
                  )}
                {contract_data?.amendment?.length > 0 && (
                  <Stack direction="column" spacing={1}>
                    <Typography fontSize="14px" fontWeight={600}>
                      Amendment Documents
                    </Typography>

                    {contract_data?.amendment?.map(
                      (item: any, index: number) => (
                        <Typography key={index} fontSize="15px">
                          {index + 1}). {item?.file_name}
                        </Typography>
                      )
                    )}
                  </Stack>
                )}
                {contract_data?.support_document?.length > 0 && (
                  <Stack direction="column" spacing={1}>
                    <Typography fontSize="14px" fontWeight={600}>
                      Support Documents
                    </Typography>

                    {contract_data?.support_document?.map(
                      (item: any, index: number) => (
                        <Typography key={index} fontSize="15px">
                          {index + 1}). {item?.file_name}
                        </Typography>
                      )
                    )}
                  </Stack>
                )}
              </Stack>
            )}

            <Stack direction="column" spacing={1} pt="15px">
              <Typography fontSize="15px" fontWeight={600}>
                Owners
              </Typography>
              {contract_data?.owners?.map(
                (item: any, index: number) =>
                  item?.id !== contract_data?.creator_details?.id && (
                    <Typography key={index} fontSize="15px">
                      {item?.first_name} {item?.last_name}
                    </Typography>
                  )
              )}
              <Typography fontSize="15px">
                {contract_data?.creator_details?.first_name}{' '}
                {contract_data?.creator_details?.last_name}
              </Typography>
              <Typography fontSize="15px">
                Uploaded on
                {dayjs(contractDetails?.created_on).format(
                  'MMMM D, YYYY h:mm A'
                )}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Button
              startIcon={<LaunchOutlinedIcon />}
              style={{ fontSize: '15px' }}
              onClick={() => {
                handleOpenContract(contractDetails?.id?.toString());
              }}
            >
              Open Contract
            </Button>
            <Button
              startIcon={<TabIcon />}
              style={{ fontSize: '15px' }}
              onClick={() => setOpenDrawer(true)}
            >
              Show connected documents
            </Button>
          </Stack>
        </Stack>
      </Popover>
      {openDrawer && (
        <ConnectedDocsDrawer
          open={openDrawer}
          onClose={handleCloseDrawer}
          contract_data={contract_data}
          contractLoading={contractLoading}
          refContractData={refContractData}
          userCanDoAction={userCanDoAction}
        />
      )}
    </>
  );
};

export default TableTooltip;
