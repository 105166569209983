import React, { useEffect, useState } from 'react';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DatasetOutlinedIcon from '@mui/icons-material/DatasetOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';
import HistoryIcon from '@mui/icons-material/History';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import { Box, Stack, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import { getSessionStorage } from '../../../Authentication/Actions/authentication';

interface Props {
  platform: string;
}

interface IconState {
  icon: any;
  name: string;
  text: string;
  displayName: string;
  path: string;
}

const umbrellaNav: IconState[] = [
  {
    icon: DriveFileRenameOutlineOutlinedIcon,
    name: 'documentlibrary',
    text: 'Access Document Library here',
    displayName: 'Contracts Library',
    path: '/documentlibrary',
  },
  // {
  //   icon: FilterAltOutlinedIcon,
  //   name: 'filter',
  //   text: 'Filter',
  //   displayName: 'Filters',
  //   path: '/filter',
  // },
];

const stylusNav = [
  {
    icon: DriveFileRenameOutlineOutlinedIcon,
    name: 'draftingreview',
    text: 'Manage your drafts here',
    displayName: 'Drafts',
    path: '/draftingreview',
  },
  {
    icon: NewspaperOutlinedIcon,
    name: 'templates',
    text: 'Manage your template here',
    displayName: 'Templates',
    path: '/templates',
  },
];

const adminNav = [
  {
    icon: DatasetOutlinedIcon,
    name: 'data-dictionary-admin',
    text: 'Manage your data dictionary here',
    displayName: 'Data Dictionary',
    path: '/admin/data-dictionary',
  },
  {
    icon: FactCheckOutlinedIcon,
    name: 'approvals-admin',
    text: 'Manage your approvals here',
    displayName: 'Approvals',
    path: '/admin/approvals',
  },
  {
    icon: SourceOutlinedIcon,
    name: 'clause-library-admin',
    text: 'Manage your clause library here',
    displayName: 'Clause Library',
    path: '/admin/clause-library-admin',
  },
  {
    icon: NewspaperOutlinedIcon,
    name: 'templates-admin',
    text: 'Manage your template here',
    displayName: 'Templates',
    path: '/admin/templates',
  },
  {
    icon: RequestPageOutlinedIcon,
    name: 'stamp-bank',
    text: 'Manage your Stamp here',
    displayName: 'Stamp Bank',
    path: '/admin/stamp-bank',
  },
  // {
  //   icon: ForwardToInboxIcon,
  //   name: 'email-audit',
  //   text: 'Manage email audit here',
  //   displayName: 'Email Audit',
  //   path: '/admin/email-audit',
  // },
  {
    icon: PersonOutlineOutlinedIcon,
    name: 'internal-users',
    text: 'Manage your Users here',
    displayName: 'Users',
    path: '/admin/internal-users',
  },
  {
    icon: AccountCircleOutlinedIcon,
    name: 'roles',
    text: 'Manage your Roles here',
    displayName: 'Roles',
    path: '/admin/roles',
  },
  {
    icon: Groups3OutlinedIcon,
    name: 'groups',
    text: 'Manage your Groups here',
    displayName: 'Groups',
    path: '/admin/groups',
  },
  {
    icon: HistoryIcon,
    name: 'sessions',
    text: 'Check users login here',
    displayName: 'Sessions',
    path: '/admin/sessions',
  },
  {
    icon: SettingsIcon,
    name: 'settings',
    test: 'Manage your Settings here',
    displayName: 'Settings',
    path: '/admin/settings',
  },
];

export const SideNavbar: React.FC<Props> = (props) => {
  const { platform } = props;
  const location = useLocation();
  const { pathname } = location;
  const [navbarList, setNavbarList] = useState<any>([]);
  const adminRole = getSessionStorage('user_profile');
  const isSuperAdmin = adminRole?.roles?.includes('Super Admin');

  useEffect(() => {
    let navbarItems: any[] = [];
    switch (platform) {
      case 'STYLLUS':
        navbarItems = stylusNav;
        break;
      case 'UMBRELLA':
        navbarItems = umbrellaNav;
        break;
      case 'ADMIN': {
        const excludeItems = [
          'internal-users',
          'groups',
          'roles',
          'email-audit',
        ];
        navbarItems = isSuperAdmin
          ? adminNav
          : adminNav.filter((item) => !excludeItems.includes(item.name));
        break;
      }
      default:
        break;
    }
    setNavbarList(navbarItems);
  }, [platform, isSuperAdmin]);

  return (
    <Box className="sidenav-layout">
      <Stack alignItems="center" spacing={4}>
        {navbarList.map((navItem: any, index: number) => {
          const Icon = navItem.icon;
          const isActive = pathname === navItem?.path;
          const color = isActive ? '#88305F' : '#1D1B20';
          return (
            <Stack
              alignItems="center"
              textAlign="center"
              key={index}
              spacing="5px"
              component={Link}
              to={navItem.path}
              sx={{
                textDecoration: 'none',
              }}
            >
              <Icon
                sx={{
                  color: color,
                }}
              />
              <Typography
                variant="body2"
                sx={{
                  color: color,
                }}
              >
                {navItem.displayName}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};

export default SideNavbar;
