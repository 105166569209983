import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { approverSequence } from '../../../Approvals/Component/CreateApprovalForm/CreateApproval';
import ShareChip from '../../../DocumentLibrary/Component/ShareModal/ShareChip';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import { fetchApprovers } from '../../../Services/Approval';
import {
  draftDeviation,
  editDraftData,
  requestApproval,
} from '../../../Services/Draft';
import { droppedMsg, sortListAlphabetical } from '../Helper';

interface Props {
  draftId: string;
  instance: any;
  combinedApproversIds: any[];
  isDraftDropped?: boolean;
  draftGroups: any[];
}

interface FormFields {
  reason: string;
  approvers: any[];
  approval_sequence: string;
}

const AddCustomIssue: React.FC<Props> = ({
  draftId,
  instance,
  combinedApproversIds,
  isDraftDropped,
  draftGroups,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const instanceRef = useRef<any>(null);

  const queryClient = useQueryClient();

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const { control, handleSubmit, resetField, watch } = useForm<FormFields>({
    defaultValues: {
      approval_sequence: 'any',
    },
  });

  const { id } = useParams<{
    id: string;
  }>();

  const selectedApprovers = watch('approvers');

  const handleClose = () => {
    setExpanded(false);
    resetField('reason');
    resetField('approvers');
    resetField('approval_sequence');
  };

  const { data: approversData, isLoading: approversLoading } = useQuery({
    queryKey: ['Approvers'],
    queryFn: fetchApprovers,
    select: (response) => {
      const groups = response.results.map((data: any) => ({
        ...data,
        name: data.first_name + ' ' + data.last_name,
      }));
      return groups || [];
    },
  });

  const filteredApproversData = useMemo(() => {
    return approversData?.filter((user: any) => user?.is_active);
  }, [approversData]);

  const { mutate: updateDraftData } = useMutation({
    mutationFn: editDraftData,
    onSuccess: () => {
      enqueueSnackbar('Successfully updated draft data!', {
        variant: 'success',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to update draft data!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: request_approval } = useMutation({
    mutationKey: ['request_approval'],
    mutationFn: (payload: any) => {
      return requestApproval(payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetch_custom_deviations'],
      });
      queryClient.invalidateQueries({
        queryKey: ['draft_approvals'],
      });
      handleClose();
    },
  });

  const { mutate: custom_deviation, isPending: deviationLoading } = useMutation(
    {
      mutationKey: ['create_custom_deviation'],
      mutationFn: (payload: any) => {
        return draftDeviation(payload);
      },
      onSuccess: (response: any) => {
        enqueueSnackbar('Successfully created custom deviation!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });

        const combinedApprovers = [
          ...selectedApprovers,
          ...combinedApproversIds,
        ];
        const uniqueApprovers = Array.from(new Set(combinedApprovers));

        const groups = draftGroups;
        approversData?.forEach((approver: any) => {
          if (uniqueApprovers.includes(approver?.id)) {
            const missingInDraftGroups = approver?.groups.filter(
              (item: string) => !draftGroups.includes(item)
            );
            if (missingInDraftGroups?.length) {
              groups.push(...missingInDraftGroups);
            }
          }
        });

        const payload = {
          id: id,
          body: {
            approvers: uniqueApprovers,
            groups: groups,
          },
        };
        updateDraftData(payload);

        const result = response;

        let priority = 0;
        result?.custom_datapoint?.map((data: any) => {
          const approversArray = data?.approvers?.map((approver: string) => {
            priority = priority + 1;
            return {
              id: approver,
              priority: priority,
            };
          });

          const payload = {
            approvers: approversArray,
            reason: data?.reason,
            approval_sequence: data?.approval_sequence,
            deviation: result?.id,
            draft: draftId,
          };
          request_approval(payload);
        });
      },
      onError: () => {
        enqueueSnackbar('Failed to create custom deviation!', {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    }
  );

  useEffect(() => {
    if (instance) {
      instanceRef.current = instance;
    }
  }, [instance]);

  const addAnnotation = useCallback(() => {
    if (instanceRef.current) {
      const { Core } = instanceRef.current;
      const { documentViewer } = Core;
      const selectedText = documentViewer.getSelectedText();
      return selectedText;
    }
    return null;
  }, []);

  const onSubmit = useCallback((data: any) => {
    const payload = {
      deviation_type: 'custom',
      error: false,
      details: null,
      condition: {
        condition: 'test',
      },
      kdp_datapoint: null,
      draft_datapoint: null,
      manual_datapoint: null,
      replaced_datapoint: null,
      reason: null,
      status: null,
      draft: draftId,
      custom_datapoint: [
        {
          selectedValue: addAnnotation(),
          ...data,
        },
      ],
    };
    if (addAnnotation()) {
      custom_deviation(payload);
    }
    if (!addAnnotation()) {
      enqueueSnackbar(
        'Please select some text from the document on left to mark as Issue ',
        {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    }
  }, []);

  return (
    <Accordion
      sx={{
        background: '#88305F24',
        boxShadow: 'none',
        borderRadius: '5px',
      }}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Stack direction="row" spacing={1}>
          <Typography>Add a custom issue</Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Typography fontSize="15px" fontWeight={700}>
              1. Select some text from the document on left to mark as Issue
            </Typography>
            <Typography fontSize="15px" fontWeight={700}>
              2. Add reason (context)
            </Typography>
            <ControlledTextField
              name="reason"
              control={control}
              required
              label="Reason"
              fullWidth
            />
            <Typography fontSize="15px" fontWeight={700}>
              3. Select approvers
            </Typography>
            <RISelectComponent
              name="approvers"
              control={control}
              label="Select approvers"
              options={sortListAlphabetical(filteredApproversData)}
              loading={approversLoading}
              isMultiselect={true}
              renderCustomComponent={(value: any, props) => (
                <ShareChip key={value?.id} {...props} label={value?.name} />
              )}
            />
            <Typography variant="caption">
              You can select multiple approvers. All approvers must approve in
              order for this rule to pass
            </Typography>
            <RadioButtonGroup
              name="approval_sequence"
              row
              options={approverSequence}
              control={control}
              isDescription
              valueKey="value"
              required
            />
            <Stack spacing={2} direction="row">
              <Tooltip
                title={droppedMsg}
                arrow
                disableHoverListener={!isDraftDropped}
              >
                <Stack>
                  <LoadingButton
                    loading={deviationLoading}
                    startIcon={<AddIcon />}
                    variant="contained"
                    type="submit"
                    disabled={!selectedApprovers?.length || isDraftDropped}
                  >
                    Create custom issue
                  </LoadingButton>
                </Stack>
              </Tooltip>
              <Button
                variant="outlined"
                startIcon={<CloseIcon />}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddCustomIssue;
