import React, { useEffect, useMemo } from 'react';

import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';

import { draftTypeOptions } from './staticData';
import { useUserData } from '../../../App/Component/UserDataProvider';
import ShareChip from '../../../DocumentLibrary/Component/ShareModal/ShareChip';
import ControlledCheckbox from '../../../RiverusUI/Components/ControlledCheckbox';
import NotepadComponent from '../../../RiverusUI/Components/NotepadComponent';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import UploadDocComponent from '../../../RiverusUI/Components/UploadDocComponent';
import { fetchRequisitionApprovers } from '../../../Services/Approval';
import { fetchCollaborators } from '../../../Services/Draft';
import { sortListAlphabetical } from '../Helper';
import LinkSelectedTemplateField from '../TemplateDrawer/LinkSelectedTemplateField';

interface Props {
  contract_type: string;
  contract_name: string;
  display_name: string;
  counterPartyDraft: any;
  setCounterPartyDraft: any;
  setSelectedLinkedDraft: any;
  contractRequestData: any;
  earlier_draft_link?: string;
  executed_contract_link?: string;
}
const DraftForm: React.FC<Props> = ({
  contract_name,
  contract_type,
  display_name,
  counterPartyDraft,
  setCounterPartyDraft,
  setSelectedLinkedDraft,
  contractRequestData,
  earlier_draft_link,
  executed_contract_link,
}) => {
  const { userIsCreator } = useUserData();
  const { watch, control, setValue } = useFormContext();
  const draft_type = watch('draft_type') || '';
  const can_upload_draft = watch('can_upload_draft') || '';
  const can_link_draft = watch('can_link_draft') || '';

  const { data: approversData, isLoading: approversLoading } = useQuery({
    queryKey: ['Approvers'],
    queryFn: fetchCollaborators,
    select: (response: any) => {
      const groups = response.results.map((data: any) => ({
        ...data,
        name: data.first_name + ' ' + data.last_name,
      }));
      return groups || [];
    },
  });

  const {
    data: requisitionApproversData,
    isLoading: requisitionApproversLoading,
  } = useQuery({
    queryKey: ['Requisition Approvers'],
    queryFn: fetchRequisitionApprovers,
    select: (response: any) => {
      const groups = response?.results?.map((data: any) => ({
        ...data,
        name: data?.first_name + ' ' + data?.last_name,
      }));
      return groups || [];
    },
  });

  const assigneeCombinedData = useMemo(() => {
    const combinedData = [
      ...(approversData || []),
      ...(requisitionApproversData || []),
    ];
    const filteredData = combinedData?.filter(
      (assignee) => assignee?.groups?.includes('/Legal') && assignee?.is_active
    );
    return Array.from(
      new Map(filteredData?.map((item) => [item?.id, item])).values()
    );
  }, [approversData, requisitionApproversData]);

  useEffect(() => {
    if (userIsCreator && contractRequestData && assigneeCombinedData) {
      const contractApprovers =
        contractRequestData?.results?.[0]?.approvers?.map(
          (approver: any) => approver?.id
        ) || [];

      const policyApprovers =
        assigneeCombinedData
          ?.filter((approver: any) => contractApprovers?.includes(approver?.id))
          ?.map((approver: any) => approver?.id) || [];

      const approverIds = Array.from(new Set([...policyApprovers]));
      setValue('approvalTypes', approverIds);
    }
  }, [userIsCreator, contractRequestData, assigneeCombinedData, setValue]);

  return (
    <Stack width="100%">
      <Grid item sm={9}>
        <Stack spacing={2}>
          <Typography fontWeight="700">Select draft type</Typography>
          <RadioButtonGroup
            row
            required
            name="draft_type"
            options={draftTypeOptions}
            valueKey="value"
            isDescription
            control={control}
          />
          {draft_type === 'counter_party' && (
            <Stack spacing={2}>
              <RISelectComponent
                name="approvalTypes"
                control={control}
                label="Select assignees"
                options={sortListAlphabetical(assigneeCombinedData)}
                loading={approversLoading || requisitionApproversLoading}
                isMultiselect
                readOnly={userIsCreator}
                renderCustomComponent={(value: any, props) => (
                  <ShareChip key={value?.id} {...props} label={value?.name} />
                )}
                textFieldProps={{
                  helperText: userIsCreator
                    ? 'You cannot modify these assignees'
                    : 'You can select multiple assignees',
                }}
              />
              <Stack direction="row" spacing={2}>
                <KeyboardReturnIcon
                  sx={{
                    transform: 'scaleX(-1)',
                    mt: '15px !important',
                  }}
                />
                <Box width="100%">
                  <UploadDocComponent
                    label="Upload Counterparty draft"
                    control={control}
                    name="counter_party_draft"
                    allowedFileTypes={['.docx', '.pdf']}
                    files={counterPartyDraft}
                    setFiles={setCounterPartyDraft}
                  />
                </Box>
              </Stack>
            </Stack>
          )}
        </Stack>
      </Grid>
      {draft_type === 'request_draft' && (
        <Stack spacing={3}>
          <Grid item sm={9}>
            <Stack spacing={1} mt={1}>
              <RISelectComponent
                name="approvalTypes"
                control={control}
                label="Select assignees"
                options={sortListAlphabetical(assigneeCombinedData)}
                loading={approversLoading || requisitionApproversLoading}
                isMultiselect
                readOnly={userIsCreator}
                renderCustomComponent={(value: any, props) => (
                  <ShareChip key={value?.id} {...props} label={value?.name} />
                )}
                textFieldProps={{
                  helperText: userIsCreator
                    ? 'You cannot modify these assignees'
                    : 'You can select multiple assignees',
                }}
              />
              <NotepadComponent name="notes" control={control} />
            </Stack>
            <Typography
              variant="caption"
              sx={{ paddingLeft: '14px', color: 'rgba(0, 0, 0, 0.6)' }}
            >
              Please add additional requirements
            </Typography>
          </Grid>
          <Stack>
            <Typography fontWeight={'700'}>Define source reference</Typography>
            <ControlledCheckbox
              name="can_link_draft"
              label="Link an earlier or executed draft"
              control={control}
            />
            {can_link_draft && (
              <Stack direction="row" spacing={2}>
                <KeyboardReturnIcon
                  sx={{
                    transform: 'scaleX(-1)',
                    mt: '15px !important',
                  }}
                />
                <LinkSelectedTemplateField
                  control={control}
                  selectedContractId={contract_type}
                  name="linked_draft"
                  selectedContractName={contract_name}
                  type="draft"
                  selectedDisplayName={display_name}
                  setSelectedLinkedDraft={setSelectedLinkedDraft}
                  earlier_draft_link={earlier_draft_link}
                  executed_contract_link={executed_contract_link}
                />
              </Stack>
            )}
            <ControlledCheckbox
              name="can_upload_draft"
              label="Upload your own reference document"
              control={control}
            />
            {can_upload_draft && (
              <Grid item sm={9}>
                <Stack direction="row" spacing={2}>
                  <KeyboardReturnIcon
                    sx={{
                      transform: 'scaleX(-1)',
                      mt: '15px !important',
                    }}
                  />
                  <Box width="100%">
                    <UploadDocComponent
                      label="Upload Document"
                      control={control}
                      name="counter_party_draft"
                      allowedFileTypes={['.pdf', '.docx']}
                      files={counterPartyDraft}
                      setFiles={setCounterPartyDraft}
                    />
                  </Box>
                </Stack>
              </Grid>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default DraftForm;
