import { GridRowId } from '@mui/x-data-grid';

import { authApi } from './authApi';

const APIPaths = {
  getContractType:
    process.env.REACT_APP_RIVERUS_CLM_API + 'admin/contracttype/',
  getGroups: process.env.REACT_APP_RIVERUS_CLM_API + 'users/groups/?type=all',
  approvalBaseUrl: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/approval/',
  getUsers: process.env.REACT_APP_RIVERUS_CLM_API + 'users/org/all/',
  getApprovers:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'users/?role=Approvers&assignee_type=internal',
  getCollaborators:
    process.env.REACT_APP_RIVERUS_CLM_API +
    'users/?role=Collaborators&assignee_type=internal',
  getCheckList: process.env.REACT_APP_RIVERUS_CLM_API + 'admin/checklist/',
  getClauseLibrary: process.env.REACT_APP_RIVERUS_CLM_API + 'clauselibrary/',
  getMergeTag:
    process.env.REACT_APP_RIVERUS_CLM_API + 'data-dictionary/get_field',
  getSectionNames:
    process.env.REACT_APP_RIVERUS_CLM_API + 'data-dictionary/section_names/',
  getContractsTags: process.env.REACT_APP_RIVERUS_CLM_API + `contracts/tags/`,
};

export const fetchContractType = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.getContractType}`);
};

export const fetchGroups = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.getGroups}`);
};

export const fetchDraftGroups = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.approvalBaseUrl}${id}/groups/`);
};

export const fetchRequestApprovals = async (params?: string): Promise<any> => {
  return await authApi.get(
    `${APIPaths.approvalBaseUrl}${params ? params : ''}`
  );
};

export const fetchMergeTag = async (params: string): Promise<any> => {
  return await authApi.get(`${APIPaths?.getMergeTag}?contract_type=${params}`);
};

export const deleteApproval = async (fileId: GridRowId[]) => {
  return await authApi.post(APIPaths.approvalBaseUrl + 'bulk-delete/', {
    ids: fileId,
  });
};

export const editApprovalData = async (id: string, body: any) => {
  const response = await authApi.patch(
    `${APIPaths.approvalBaseUrl}${id}/`,
    body
  );
  return response;
};

export const getUsers = async (): Promise<any> => {
  const URL = process.env.REACT_APP_RIVERUS_CLM_API + 'users/org/all/';
  return await authApi.get(`${URL}`);
};

export const getAllUsers = async (params?: any): Promise<any> => {
  const URL = `${process.env.REACT_APP_RIVERUS_CLM_API}users/org/${params ? params : ''}`;
  return await authApi.get(`${URL}`);
};

export const fetchApprovers = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.getApprovers}`);
};

export const fetchRequisitionApprovers = async (): Promise<any> => {
  return await authApi.get(
    'users/?role=Requisition Approvers&assignee_type=internal'
  );
};

export const fetchCollaborators = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.getCollaborators}`);
};

export const fetchCheckList = async (contractId?: string): Promise<any> => {
  return await authApi.get(
    `${APIPaths.getCheckList}?checklist_type=custom&contract_type=${contractId}`
  );
};

export const deleteChecklist = async (id: string) => {
  return await authApi.delete(`${APIPaths.getCheckList}${id}/`);
};

export const addChecklist = async (payload: any): Promise<any> => {
  return await authApi
    .post(`${APIPaths.getCheckList}`, payload)
    .then((response) => response.data);
};
export const createApproval = async (body: any): Promise<any> => {
  return await authApi.post(APIPaths.approvalBaseUrl, body);
};

export const updateApproval = async (id: string, body: any) => {
  return await authApi.put(`${APIPaths.approvalBaseUrl}${id}/`, body);
};
export const getClauseLibrary = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.getClauseLibrary}`);
};

export const getClauseLibraryData = async (params: any): Promise<any> => {
  return await authApi.get(`${APIPaths.getClauseLibrary}${params}`);
};

export const fetchClauseLibraryById = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.getClauseLibrary}${id}/`);
};

export const updateClauseLibrary = async (payload: any) => {
  const { id, body } = payload;
  return await authApi.patch(`${APIPaths.getClauseLibrary}${id}/`, body);
};

export const deleteClauseLibrary = async (payload: any) => {
  return await authApi.delete(`${APIPaths.getClauseLibrary}delete/`, {
    data: payload,
  });
};

export const getSectionNames = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.getSectionNames}`);
};

export const addClauseLibrary = async (payload: any): Promise<any> => {
  return await authApi
    .post(`${APIPaths.getClauseLibrary}`, payload)
    .then((response) => response.data);
};

export const addTags = async (payload: any): Promise<any> => {
  return await authApi
    .post(`${APIPaths.getContractsTags}`, payload)
    .then((response) => response.data);
};

export const fetchClauseLibraryData = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.getClauseLibrary}`);
};

export const fetchApprovalData = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.approvalBaseUrl}${id}/`);
};

export const fetchContractCategory = async (id: string): Promise<any> => {
  return await authApi.get(`admin/contract-category/?contract_types=${id}`);
};

export const fetchAllContractCategory = async (): Promise<any> => {
  return await authApi.get(`admin/contract-category/`);
};

export const fetchContractTypesHasPolicy = async (
  params = ''
): Promise<any> => {
  return await authApi.get(`admin/contracttype/${params}`);
};
