import React, { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, IconButton, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';

import {
  fetchDraftById,
  fetchExecutedContractsById,
  fetchTemplateById,
} from '../../../Services/Draft';
import PdfView from '../PdfView';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  templateId: string;
  type: string;
}

const TemplateViewDrawer: React.FC<Props> = ({
  open,
  onClose,
  templateId,
  type,
}) => {
  const [accessUrl, setAccessUrl] = useState<string>('');
  const [instance, setInstance] = useState<any>();
  const [draftData, setDraftData] = useState<any>({});

  const { data: templateData } = useQuery({
    queryKey: ['get_access_url', templateId],
    queryFn: async () => {
      const response = await fetchTemplateById(templateId);
      setAccessUrl(response?.access_url);
      return response;
    },
    enabled: !!templateId && type === 'template',
  });

  const { data: earlierData } = useQuery({
    queryKey: ['get_earlier_access_url', templateId],
    queryFn: async () => {
      const response: any = await fetchDraftById(templateId);
      setAccessUrl(response?.access_url);
      return response;
    },
    enabled: !!templateId && type === 'earlierDraft',
  });

  const { data: executedContractData } = useQuery({
    queryKey: ['get_executed_contract_access_url', templateId],
    queryFn: async () => {
      const response = await fetchExecutedContractsById(templateId);
      setAccessUrl(response?.location);
      return response;
    },
    enabled: !!templateId && type === 'executedDraft',
  });

  useEffect(() => {
    if (templateId) {
      if (type === 'template' && templateData) {
        setDraftData(templateData);
      } else if (type === 'earlierDraft' && earlierData) {
        setDraftData(earlierData);
      } else if (type === 'executedDraft' && executedContractData) {
        setDraftData(executedContractData);
      }
    }
  }, [templateData, executedContractData, earlierData]);

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Stack spacing={2} width="900px" marginX="15px">
        <Box display="flex" alignItems="center" width="900px">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography>Search and select a templates</Typography>
        </Box>
        <PdfView
          draftData={draftData}
          fileLink={accessUrl}
          instance={instance}
          setInstance={setInstance}
        />
      </Stack>
    </Drawer>
  );
};

export default TemplateViewDrawer;
