import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, InputBase } from '@mui/material';

interface IProps {
  setIsSearch: VoidFunction;
  renderCustomInput?: () => any;
  handleChange?: any;
  placeholder?: string;
  selectedValue?: any;
}

const TableSearchInputComponent = (props: IProps) => {
  const {
    setIsSearch,
    renderCustomInput,
    handleChange,
    placeholder,
    selectedValue,
  } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', position: 'fixed' }}>
      {renderCustomInput ? (
        <Box
          sx={{
            minWidth: '180px',
            maxWidth: '250px',
            display: 'flex',
            overflowX: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {renderCustomInput()}
        </Box>
      ) : (
        <InputBase
          sx={{ width: '80%' }}
          placeholder={placeholder}
          inputProps={{ 'aria-label': placeholder }}
          onChange={handleChange}
        />
      )}
      {!selectedValue?.length && (
        <IconButton
          type="button"
          sx={{ p: '1px' }}
          aria-label="search"
          onClick={setIsSearch}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default TableSearchInputComponent;
