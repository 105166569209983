import React, { FC } from 'react';

import { Button, Dialog, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

import { getSessionStorage } from '../../Authentication/Actions/authentication';
import { requestAccess } from '../../Services/Draft';

interface IProps {
  isOpen: boolean;
  data: any;
  onClose: VoidFunction;
  id?: string;
}

const DraftViewAlert: FC<IProps> = ({ id, isOpen, data, onClose }) => {
  const user_id = getSessionStorage('user_id') || '';
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const { mutate: requestAccessMutation } = useMutation({
    mutationKey: ['request-access'],
    mutationFn: requestAccess,
    onSuccess: () => {
      enqueueSnackbar(
        `Mail is send to the ${data.first_name} ${data.last_name}!`,
        {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }
      );
    },
  });

  const handleClose = () => {
    navigate('/draftingreview');
    onClose?.();
  };

  return (
    <Dialog open={isOpen}>
      <Stack width="fit-content" padding={3} spacing={2} alignItems="center">
        <Typography>
          {user_id === data.id
            ? 'You are editing this document in Riverus Ally (Riverus MS Word add-in). Save and close the document in Ally to access it within Stylus.'
            : `${data.first_name} ${data.last_name} is editing this document in Riverus Ally (Riverus MS
          Word add-in). Notify ${data.first_name} ${data.last_name} to save and close the document in Ally in order to access from Stylus`}
        </Typography>
        <Stack direction="row" alignSelf="end">
          <Button variant="contained" onClick={handleClose}>
            Ok
          </Button>
          {user_id !== data.id && (
            <Button
              variant="outlined"
              onClick={() =>
                requestAccessMutation({
                  id: id,
                  request_access: true,
                })
              }
            >
              Request Access
            </Button>
          )}
        </Stack>
      </Stack>
    </Dialog>
  );
};
export default DraftViewAlert;
