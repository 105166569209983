import React, { useMemo } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { Control } from 'react-hook-form';

import { useUserData } from '../../../App/Component/UserDataProvider';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import colors from '../../../RiverusUI/Theme/colors';
import { droppedMsg, sortListAlphabetical } from '../Helper';

interface Props {
  control: Control;
  assigneeData: any[];
  onSubmit?: any;
  userIsOwner: boolean;
  isDraftDropped?: boolean;
  loadingAddOwner: boolean;
}

const AddAssignee: React.FC<Props> = ({
  control,
  assigneeData,
  onSubmit,
  userIsOwner,
  isDraftDropped,
  loadingAddOwner,
}) => {
  const { userIsAdmin } = useUserData();

  const filteredAssigneeData = useMemo(() => {
    return assigneeData?.filter((user: any) => user?.is_active);
  }, [assigneeData]);

  return (
    <Box
      sx={{ backgroundColor: colors?.riPrimary[10], flexGrow: 1 }}
      padding={2}
    >
      <Typography padding={1} fontSize="16px" fontWeight="500">
        Add Co-Owner
      </Typography>
      <RISelectComponent
        name="assignee"
        control={control}
        label="Select user"
        options={sortListAlphabetical(filteredAssigneeData)}
      />
      <Stack direction="row" justifyContent="flex-end">
        <Tooltip
          title={droppedMsg}
          arrow
          disableHoverListener={!isDraftDropped}
        >
          <Stack>
            <LoadingButton
              loading={loadingAddOwner}
              onClick={onSubmit}
              disabled={(userIsOwner && !userIsAdmin) || isDraftDropped}
            >
              Add Owner
            </LoadingButton>
          </Stack>
        </Tooltip>
      </Stack>
    </Box>
  );
};

export default AddAssignee;
