import React, { FC, useMemo } from 'react';

import { Button, Stack, Typography } from '@mui/material';

import CustomModal from '../../RiverusUI/Components/CustomModal';

interface IProps {
  isOpen: boolean;
  templateError: any;
  onClose: VoidFunction;
}

const TemplateErrorAlert: FC<IProps> = ({ isOpen, onClose, templateError }) => {
  const showMessage = useMemo(() => {
    let show = false;
    templateError.map((error: any) => {
      if (
        error.properties.id !== 'unclosed_tag' &&
        error.properties.id !== 'unopened_tag' &&
        error.properties.id !== 'unclosed_loop' &&
        error.properties.id !== 'unopened_loop' &&
        error.properties.id !== 'closing_tag_does_not_match_opening_tag'
      ) {
        show = true;
      }
    });
    return show;
  }, []);

  return (
    <CustomModal
      title="There is error in the document Please do the changes and reupload it."
      open={isOpen}
      handleClose={onClose}
    >
      <Stack flex={1} padding={3} spacing={2}>
        {templateError?.map((error: any, index: number) =>
          error.properties.id === 'unclosed_tag' ? (
            <Stack key={index} flex={1}>
              <Typography
                sx={{ fontSize: '18px', fontWeight: 'bold', color: '#6D264C' }}
              >
                Unclosed Tag
              </Typography>

              <Stack sx={{ background: '#c2c0c057' }} width="100%" p="4px">
                <Typography fontSize="14px">
                  <strong>Illustration - </strong> {` {name !`}
                </Typography>
                <Typography sx={{ color: 'GrayText' }} fontSize="14px">
                  {error.properties.explanation}.
                </Typography>
              </Stack>
              <Typography fontSize="14px">
                <strong>Your Actionable:- </strong> Please open/delete the tag.
              </Typography>
            </Stack>
          ) : error.properties.id === 'unopened_tag' ? (
            <Stack key={index} flex={1}>
              <Typography
                sx={{ fontSize: '18px', fontWeight: 'bold', color: '#6D264C' }}
              >
                Unopened Tag
              </Typography>
              <Stack sx={{ background: '#c2c0c057' }} width="100%" p="4px">
                <Typography fontSize="14px">
                  <strong>Illustration</strong> - {` name}!`}
                </Typography>
                <Typography sx={{ color: 'GrayText', fontSize: '14px' }}>
                  {error.properties.explanation}.
                </Typography>
              </Stack>
              <Typography fontSize="14px">
                <strong>Your Actionable:- </strong> Please close/delete the tag.
              </Typography>
            </Stack>
          ) : error.properties.id ===
            'closing_tag_does_not_match_opening_tag' ? (
            <Stack key={index} flex={1}>
              <Typography
                sx={{ fontSize: '18px', fontWeight: 'bold', color: '#6D264C' }}
              >
                Mismatched Tag
              </Typography>
              <Stack sx={{ background: '#c2c0c057' }} width="100%" p="4px">
                <Typography fontSize="14px">
                  <strong>Illustration</strong> - {`{#users}{name}{/people}`}
                </Typography>
                <Typography sx={{ color: 'GrayText', fontSize: '14px' }}>
                  {error.properties.explanation}.
                </Typography>
              </Stack>
              <Typography fontSize="14px">
                <strong>Your Actionable:- </strong> Please use the same name or
                delete the Tag.
              </Typography>
            </Stack>
          ) : error.properties.id === 'unclosed_loop' ||
            error.properties.id === 'unopened_loop' ? (
            <Stack key={index} flex={1}>
              <Typography
                sx={{ fontSize: '18px', fontWeight: 'bold', color: '#6D264C' }}
              >
                Unclosed/Unopened Loop
              </Typography>

              <Stack sx={{ background: '#c2c0c057' }} width="100%" p="4px">
                <Typography fontSize="14px">
                  <strong>Illustration</strong> - {`{#users}{name}`}
                </Typography>
                <Typography fontSize="14px">or</Typography>
                <Typography fontSize="14px">{`{name}{/users}`}</Typography>
                <Typography sx={{ color: 'GrayText', fontSize: '14px' }}>
                  {error.properties.explanation}.
                </Typography>
              </Stack>
              <Typography fontSize="14px">
                <strong>Your Actionable:- </strong> Please close/delete the
                loop.
              </Typography>
            </Stack>
          ) : null
        )}
        {showMessage ? (
          <Typography fontSize="14px">
            Your document is not being processed. There is error(s) in the
            document. Please contact your account executive at Riverus Contracts
            Platform.
          </Typography>
        ) : null}
        <Stack alignItems="flex-end">
          <Button variant="contained" onClick={onClose}>
            Ok
          </Button>
        </Stack>
      </Stack>
    </CustomModal>
  );
};
export default TemplateErrorAlert;
