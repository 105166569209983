import { authApi } from './authApi';

const baseUrl = process.env.REACT_APP_RIVERUS_CLM_API;

const APIPaths = {
  groupUrl: baseUrl + 'users/groups/',
  reminderUrl: baseUrl + 'reminder/',
};

export const fetchGroups = async () => {
  return await authApi.get(`${APIPaths.groupUrl}`);
};

export const fetchReminders = async (params: string) => {
  return await authApi.get(`${APIPaths.reminderUrl}?${params}`);
};

export const fetchReminderById = async (id: string): Promise<any> => {
  return await authApi.get(`${APIPaths.reminderUrl}${id}/`);
};

export const fetchReminderByUserId = async (payload: any) => {
  return await authApi.post(
    `${APIPaths.reminderUrl}get-reminder-by-user/`,
    payload
  );
};

export const addContractReminderComments = async (payload: any) => {
  return await authApi.post(`reminder/reminder-comments/`, payload);
};

export const createBulkReminder = async (payload: any) => {
  return await authApi.post('reminder/bulk-reminders/', payload);
};

export const editBulkReminder = async (payload: any) => {
  return await authApi.post('reminder/reminder-update/', payload);
};

export const fetchReminderDraftItem = async (id: string): Promise<any> => {
  return await authApi.get(`reminder/?draft_item=${id}`);
};
export const addContractReminderBulkComments = async (payload: any) => {
  return await authApi.post(
    `${APIPaths.reminderUrl}reminder-comments/comments-bulk-update/`,
    payload
  );
};
