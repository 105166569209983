import React, { useEffect, useMemo } from 'react';

import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';

import ViewDraft from './ViewExternalDraft';
import {
  getSessionStorage,
  setSessionStorage,
} from '../Authentication/Actions/authentication';

const VerifyExternalUser = () => {
  const { search } = useLocation();
  const queryParams: any = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );
  const auth_token = queryParams.get('auth_token');
  const external_token = queryParams.get('external_token');
  const tab = queryParams.get('tab');
  const email = queryParams.get('email');
  const external_id = queryParams.get('external_id');
  const signatory_id = queryParams.get('signatory_id');

  const localExternalAuthToken = useMemo(
    () => getSessionStorage('external_auth_token'),
    []
  );

  useEffect(() => {
    if (auth_token && external_token) {
      setSessionStorage('external_auth_token', auth_token);
      setSessionStorage('external_token', external_token);
      setSessionStorage('external_email', email);
      setSessionStorage('external_user_id', external_id);
      tab === 'signature' &&
        setSessionStorage('external_signatory_id', signatory_id);
      if (!localExternalAuthToken || localExternalAuthToken !== auth_token) {
        window.location.reload();
      }
    }
  }, [auth_token, external_token, email, localExternalAuthToken]);

  return (
    <Box sx={{ height: '100vh' }}>{tab && <ViewDraft activeTab={tab} />}</Box>
  );
};

export default VerifyExternalUser;
