import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Divider, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import AddAssignee from './AddAssignee';
import AssigneeList from './AssigneeList';
import { useUserData } from '../../../App/Component/UserDataProvider';
import {
  fetchAssigneeList,
  updateAssigneeList,
} from '../../../Services/Access';
import { assigneesList, fetchVersionHistory } from '../../../Services/Draft';
import { draftStatus } from '../../State/DraftState';

interface Props {
  row: any;
}

const AssigneeDrawer: React.FC<Props> = ({ row }) => {
  const { control, handleSubmit, reset } = useForm<any>();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const remainingCalls = useRef(0);
  const [approversData, setApproversData] = useState<any[]>([]);
  const [collaboratorsData, setCollaboratorsData] = useState<any[]>([]);
  const [assigneeDataDrawer, setAssigneeDataDrawer] = useState<any>([]);

  const ownerList = useMemo(() => {
    return assigneeDataDrawer?.filter(
      (item: any) =>
        item.assigned_role && item.assigned_role?.includes('Owners')
    );
  }, [assigneeDataDrawer]);

  // Assignee dropdown list
  const { data: assigneeData } = useQuery({
    queryKey: ['assignee'],
    queryFn: fetchAssigneeList,
    select: (response) => {
      const users = response?.users || [];
      // Filter users based on userGroup
      const filteredUsers = users.filter((user: any) => {
        return user?.groups.some((group: any) => row.groups?.includes(group));
      });
      const modifiedUsers = filteredUsers.map((user: any) => {
        const name = user.first_name + ' ' + user.last_name;
        return { ...user, name };
      });
      return modifiedUsers || [];
    },
  });

  const { data: versionHistory } = useQuery({
    queryKey: ['assignee_version_history', row?.draftID],
    queryFn: async () => fetchVersionHistory(row?.draftID),
    select: (response: any) => {
      return response?.results;
    },
    enabled: !!row?.draftID,
  });

  const newVersionHistory = versionHistory?.length
    ? versionHistory.flatMap((item: any) =>
        item?.drop_details?.length
          ? item.drop_details?.map((detail: any) => ({
              ...item,
              id: detail?.id,
              dropUndropDetails: detail,
            }))
          : []
      )
    : [];

  const { user_id } = useUserData();

  const userIsOwner = useMemo(
    () => row?.owners?.find((owner: any) => owner?.id === user_id),
    [row, user_id]
  );

  // update api
  const { mutate: updateOwnerList, isPending: loadingAddOwner } = useMutation({
    mutationFn: updateAssigneeList,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['update-assignee'],
      });
      remainingCalls.current -= 1;
      if (remainingCalls.current === 0) {
        queryClient.invalidateQueries({
          queryKey: ['drafts'],
        });
        enqueueSnackbar('Owner data updated successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to update owner data!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: assigneesListMutation } = useMutation({
    mutationKey: ['assignee_list'],
    mutationFn: assigneesList,
    onSuccess: (response: any, variables: any) => {
      if (variables.assignee_type === 'approvers') {
        setApproversData(response);
      } else if (variables.assignee_type === 'collaborators') {
        setCollaboratorsData(response);
      }
    },
    onError: () => {
      enqueueSnackbar('Error fetching assignees list!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    const approversPayload = {
      draft_id: row?.draftID,
      assignee_type: 'approvers',
      type: 'history',
    };
    const collaboratorsPayload = {
      draft_id: row?.draftID,
      assignee_type: 'collaborators',
      type: 'history',
    };
    assigneesListMutation(approversPayload);
    assigneesListMutation(collaboratorsPayload);
  }, [row?.draftID, assigneesListMutation]);

  useEffect(() => {
    const signatories = [
      ...(row?.old_signatories || []),
      ...(row?.signatories || []),
    ];
    const owners = row?.owners || [];

    const approvers = Object.values(approversData).map((i: any) => {
      i['assigned_role'] = ['Approver'];
      return i;
    });
    const collaborators = Object.values(collaboratorsData).map((i: any) => {
      i['assigned_role'] = ['Collaborators'];
      return i;
    });

    const uniqueSignatories = Array.from(
      new Set(
        signatories?.map((signatory: any) =>
          signatory?.id ? signatory?.id : signatory?.email
        )
      )
    ).map((id) =>
      signatories?.find((signatory: any) =>
        signatory?.id ? signatory?.id === id : signatory?.email === id
      )
    );
    const signatoriesData = uniqueSignatories?.map((i: any) => ({
      ...i,
      assigned_role: ['Signatories'],
    }));

    const ownersData = owners.map((i: any) => ({
      ...i,
      assigned_role: ['Owners'],
    }));

    let creator: any = null;
    if (row?.creator?.roles?.includes('Creators') || row?.creator?.id) {
      creator = {
        ...row.creator,
        assigned_role: ['Creators'],
      };
    }

    let spreadData: any[] = [
      ...collaborators,
      ...approvers,
      ...signatoriesData,
      ...ownersData,
      ...(creator ? [creator] : []),
    ];

    spreadData = spreadData.map((data: any) => {
      if (
        data?.user_type === 'external' ||
        data?.assignee_type === 'external'
      ) {
        const firstName = data?.first_name;
        const lastName =
          data?.first_name === data?.last_name ? '' : data?.last_name;
        return { ...data, first_name: firstName, last_name: lastName };
      }
      return data;
    });

    setAssigneeDataDrawer(spreadData);
  }, [approversData, collaboratorsData, row]);

  const onSubmit = useCallback(
    (data: any) => {
      const matchedAssignee = assigneeData?.find(
        (user: any) => user?.id === data?.assignee
      );

      const isAlreadyOwner = ownerList?.some(
        (user: any) => user?.id === data?.assignee
      );

      if (!isAlreadyOwner && matchedAssignee) {
        const updatedOwnerIdList = [
          ...ownerList.map((user: any) => user.id),
          matchedAssignee?.id,
        ];
        const newAssignee = { ...matchedAssignee, assigned_role: ['Owners'] };

        setAssigneeDataDrawer((prev: any) => [...prev, newAssignee]);

        const groups = row?.groups;
        assigneeData?.forEach((approver: any) => {
          if (updatedOwnerIdList?.includes(approver?.id)) {
            const missingInDraftGroups = approver?.groups.filter(
              (item: string) => !row?.groups.includes(item)
            );
            if (missingInDraftGroups?.length) {
              groups.push(...missingInDraftGroups);
            }
          }
        });

        versionHistory?.[0]?.all_drafts?.forEach((draft: any) => {
          remainingCalls.current = versionHistory?.[0]?.all_drafts?.length;
          const draftId = Object.keys(draft)[0];
          const payload = {
            id: draftId,
            body: {
              owners: updatedOwnerIdList,
              groups: groups,
            },
          };
          updateOwnerList(payload);
        });
        reset();
      }
      if (isAlreadyOwner) {
        enqueueSnackbar('This owner data is already present!', {
          variant: 'info',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
    },
    [row, assigneeData, ownerList, updateOwnerList, versionHistory]
  );

  const isDraftDropped = useMemo(
    () => row?.status === draftStatus?.Draft_Dropped,
    [row?.status]
  );

  return (
    <Stack width="100%" mt={1}>
      <AddAssignee
        control={control}
        assigneeData={assigneeData}
        onSubmit={handleSubmit(onSubmit)}
        userIsOwner={!userIsOwner}
        isDraftDropped={isDraftDropped}
        loadingAddOwner={loadingAddOwner}
      />

      <Typography margin={2} fontSize="16px" fontWeight="500">
        People with access
      </Typography>
      <AssigneeList
        assigneeList={assigneeDataDrawer}
        row={row}
        approversData={approversData}
        collaboratorsData={collaboratorsData}
      />
      {newVersionHistory?.length !== 0 && (
        <Stack spacing={2} mx={2} mb={3}>
          <Divider />
          <Stack spacing={2}>
            <Stack fontWeight={600}>Draft Dropped/Undropped History :-</Stack>
            <Stack spacing={1}>
              {newVersionHistory?.map((item: any) => (
                <Stack direction="row" key={item?.id}>
                  <Typography variant="body1">
                    {item?.dropUndropDetails?.status === 'Dropped'
                      ? 'Dropped'
                      : 'Undropped'}{' '}
                    Date:{' '}
                    {dayjs(item?.dropUndropDetails?.created_on).format(
                      'DD/MM/YYYY, h:mm A'
                    )}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default AssigneeDrawer;
