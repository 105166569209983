import React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import UserAuthChecker from './HOC/auth';
import Admin from '../../Admin';
import { UserDataProvider } from '../../App/Component/UserDataProvider';
import ApprovalsContainer from '../../Approvals/Component/ApprovalsComponent';
import ClauseLibraryAdminComponent from '../../ClauseLibrary/Admin/Component/ClauseLibraryAdminComponent';
import DataDictionaryAdminContainer from '../../DataDictionary/Admin';
import DataDictionaryCreateType from '../../DataDictionary/Admin/Component/DataDictionaryCreateType';
import DocumentLibraryComponent from '../../DocumentLibrary/Component/DocumentLibraryComponent';
import DocumentView from '../../DocumentView/Container/documentCon';
import DraftComponent from '../../Draft/Component/DraftComponent';
import ViewDraft from '../../Draft/Component/ViewDraft';
import EmailAudit from '../../EmailAudit/EmailAudit';
import VerifyExternalUser from '../../ExternalUserFlow/VerifyExternalUser';
import FilterComponent from '../../Filter/Components/FilterComponent';
import Groups from '../../Groups';
import ReminderComponent from '../../Reminder/ReminderComponent';
import DraftLayout from '../../RiverusUI/Components/Layouts/DraftLayout';
import Roles from '../../Roles';
import Root from '../../Root';
import Search from '../../Search/Components/search';
import Sessions from '../../Sessions/Sessions';
import Settings from '../../Setting';
import StampBank from '../../StampBank';
import AdminTemplate from '../../Templates/Admin';
import ViewTemplate from '../../Templates/Admin/Component/ViewTemplate';
import UserManagement from '../../UserManagement';

export const privateRouter = createBrowserRouter([
  {
    path: '*',
    Component: UserAuthChecker(Root),
    children: [
      {
        path: 'document/:page/:id/:requestid?',
        element: <DocumentView />,
      },
      {
        path: 'search/:query',
        element: <Search />,
      },
      {
        path: 'documentlibrary',
        element: <DocumentLibraryComponent />,
      },
      {
        path: 'reminders',
        element: <ReminderComponent />,
      },
      {
        path: 'templates',
        element: <AdminTemplate />,
      },
      {
        path: 'admin/templates',
        element: <AdminTemplate />,
      },
      {
        path: 'template/:id/:page',
        element: <ViewTemplate />,
      },
      {
        path: 'admin/stamp-bank',
        element: <StampBank />,
      },
      {
        path: 'admin/internal-users',
        element: <UserManagement />,
      },
      {
        path: 'admin/groups',
        element: <Groups />,
      },
      {
        path: 'admin/roles',
        element: <Roles />,
      },
      {
        path: 'draftingreview',
        element: <DraftComponent />,
      },
      {
        path: 'draftingreview/:id',
        element: <DraftComponent />,
      },
      {
        path: 'approvals',
        element: <ApprovalsContainer />,
      },
      {
        path: 'admin/data-dictionary/:id/',
        element: <DataDictionaryCreateType />,
      },
      {
        path: 'admin/data-dictionary',
        element: <DataDictionaryAdminContainer />,
      },
      {
        path: 'admin/clause-library-admin',
        element: <ClauseLibraryAdminComponent />,
      },
      {
        path: 'admin/settings',
        element: <Settings />,
      },
      {
        path: 'admin/sessions',
        element: <Sessions />,
      },
      {
        path: 'admin/email-audit',
        element: <EmailAudit />,
      },
      {
        path: 'admin',
        element: <Admin />,
      },
      {
        path: 'filter',
        element: <FilterComponent />,
      },
      {
        path: 'draft',
        element: <DraftLayout />,
        children: [{ path: ':version/:id?', element: <ViewDraft /> }],
      },
    ],
  },
]);

export const externalRouter = createBrowserRouter([
  {
    path: '*',
    children: [
      {
        path: 'draft/:version/:id',
        element: (
          <UserDataProvider>
            <VerifyExternalUser />
          </UserDataProvider>
        ),
      },
    ],
  },
]);
