import { authApi } from './authApi';

const APIPaths = {
  getAssignee: process.env.REACT_APP_RIVERUS_CLM_API + 'users/org/all/',
  updateAssignee: process.env.REACT_APP_RIVERUS_CLM_API + 'stylus/draft/',
};

export const fetchAssigneeList = async (): Promise<any> => {
  return await authApi.get(`${APIPaths.getAssignee}`);
};

export const updateAssigneeList = async (payload: any) => {
  return await authApi.patch(
    `${APIPaths?.updateAssignee}${payload.id}/`,
    payload.body
  );
};
