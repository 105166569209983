import React, { FC, useCallback, useEffect, useState } from 'react';

import TagIcon from '@mui/icons-material/Tag';
import { Button, LinearProgress, Stack, Typography } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { FormProvider, useForm } from 'react-hook-form';

import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { isRiverusAdmin } from '../../../DataDictionary/DDUtils';
import { sortListAlphabetical } from '../../../Draft/Component/Helper';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import CustomModal from '../../../RiverusUI/Components/CustomModal';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import UploadDocComponent from '../../../RiverusUI/Components/UploadDocComponent';
import { fetchGroups } from '../../../Services/Approval';
import {
  getS3PresignedUrl,
  upload_file_in_s3_bucket,
} from '../../../Services/Draft';
import {
  addTemplateTags,
  fetchTemplates,
  fetchTemplateTags,
  getContractTypes,
  getTemplateById,
  updateBulkTemplates,
} from '../../../Services/Template';
import { selectTypeDocument } from '../StaticData';

interface Props {
  open: boolean;
  onClose: () => void;
  type: string;
  template_id?: GridRowId[];
}

const SparkMD5 = require('spark-md5');

const CreateTemplate: FC<Props> = (props) => {
  const { open, onClose, type, template_id } = props;

  const methods = useForm();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const {
    handleSubmit,
    control,
    watch,
    formState: { isValid },
    setValue,
  } = methods;

  const groupsField = watch('groups') || [];

  const [uploadedFile, setUploadedFile] = useState<any>([]);
  const [uploadProgress, setUploadProgress] = useState<any>({});
  const [fileHash, setFileHash] = useState<string>('');

  const { data: contracts, isLoading: contractsLoading } = useQuery({
    queryKey: ['template-contracts'],
    queryFn: getContractTypes,
    select: (response: any) => response.results,
  });

  const { data: templateData } = useQuery({
    queryKey: ['template-data-by-id', template_id],
    queryFn: () => getTemplateById(template_id?.[0] as string),
    enabled: !!template_id && template_id?.length === 1,
  });

  const { data: templates, isLoading: templatesLoading } = useQuery({
    queryKey: ['templates-dropdown'],
    queryFn: () => fetchTemplates('?file_type=template'),
    select: (response: any) => {
      return response.results.map((item: any) => {
        if (!item.name) {
          item.name = item.file_name;
        }
        return item;
      });
    },
  });

  const { data: tags, isLoading: tagsLoading } = useQuery({
    queryKey: ['template-tags'],
    queryFn: fetchTemplateTags,
    select: (response: any) => {
      return response.results.map((data: any) => {
        const newData = {
          id: data.id,
          name: data.tag_name,
        };
        return newData;
      });
    },
  });

  const { data: groups, isLoading: groupLoading } = useQuery({
    queryKey: ['groups'],
    queryFn: fetchGroups,
  });

  const { mutate: addTagsMutation } = useMutation({
    mutationKey: ['add_template_tags'],
    mutationFn: addTemplateTags,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['template-tags'] });
    },
  });

  useEffect(() => {
    if (templateData && template_id) {
      const tags = (templateData as any)?.tags;
      const ids = tags?.map((tag: any) => tag?.id);
      setValue('tags', ids);
      setValue('linked_template', (templateData as any)?.linked_template);
    }
  }, [setValue, templateData, template_id]);

  const onUploadProgress = React.useCallback(
    (progressEvent: any) => {
      const reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      const file = uploadedFile[0];
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
          setFileHash(hexHash);
          setUploadProgress(uploadProgress);
        };
      }
    },
    [uploadedFile]
  );

  const { mutate: uploadDocInS3Bucket } = useMutation({
    mutationKey: ['upload_document_in_S3_bucket'],
    mutationFn: upload_file_in_s3_bucket,
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({
        queryKey: ['admin-templates'],
      });
      queryClient.invalidateQueries({
        queryKey: ['template_statistics'],
      });
    },
    onError: () => {
      enqueueSnackbar('Failed to upload the document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadDocument, isPending: loadingUploadDoc } = useMutation({
    mutationKey: ['upload_template_document', uploadedFile],
    mutationFn: getS3PresignedUrl,
    onSuccess: (response: any) => {
      if (response) {
        const file = uploadedFile[0];
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };
          uploadDocInS3Bucket({
            presignedPostData: response?.presigned_url,
            file: file,
            onHandleFileProgress: onHandleFileProgress,
          });
        }
      }
    },
    onError: (error: any) => {
      const responseData = error?.response?.data?.__all__?.[0]
        ? 'The template already exists'
        : error?.response?.data?.errors
          ? error?.response?.data?.errors?.[0]
          : 'Failed to Upload Document!';
      enqueueSnackbar(responseData, {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: updateBulkTemplateMutation } = useMutation({
    mutationFn: updateBulkTemplates,
    onSuccess: () => {
      onClose();
      queryClient.invalidateQueries({
        queryKey: ['admin-templates'],
      });
    },
  });

  const onSubmit = (data: any) => {
    if (template_id) {
      const payload = {
        data:
          type === 'edit_linked_template'
            ? { linked_template: data.linked_template }
            : {
                tag_ids: data?.tags,
                template_ids: template_id,
              },
      };
      updateBulkTemplateMutation(payload);
    }
    const reader = new FileReader();
    const file = uploadedFile[0];
    const contract = contracts.filter(
      (item: any) => item.id === data.contract_type
    );
    if (file) {
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const hexHash = SparkMD5.hash(reader.result);
        const file_ = {
          file_hash: hexHash,
          file_name: file.name,
          file_size: file.size,
          file_type: type,
          creation_type: isRiverusAdmin() ? 'system' : 'custom',
          name: data.name,
          group: data.groups,
          tags: data.tags,
          ...(type === 'support_doc_template'
            ? {
                linked_template: data.linked_template,
                template_type: data.template_type,
              }
            : {
                contract_type: [data.contract_type],
                template_type: contract[0].name,
              }),
        };
        uploadDocument(file_);
      };
    }
  };

  const renderFormFields = useCallback(() => {
    switch (type) {
      case 'edit_linked_template':
        return (
          <>
            <Stack gap={1}>
              <Typography fontWeight={'700'}>
                (optional) Linked Template
              </Typography>
              <RISelectComponent
                name="linked_template"
                control={control}
                label="Select a linked template"
                options={templates}
                loading={templatesLoading}
              />
            </Stack>
            <Button variant="contained" type="submit" disabled={!isValid}>
              Save
            </Button>
          </>
        );
      case 'edit_tags':
        return (
          <>
            <RISelectComponent
              name="tags"
              control={control}
              label="Tags"
              options={sortListAlphabetical(tags) || []}
              loading={tagsLoading}
              isMultiselect
              renderCustomComponent={(value: any, props) => (
                <CustomChip
                  {...props}
                  icon={
                    <TagIcon
                      style={{
                        color: '#6D264C',
                      }}
                    />
                  }
                  label={value?.name}
                />
              )}
              canCreateNew
              addNewValue={(value) => addTagsMutation({ tag_name: value })}
            />
            <Button variant="contained" type="submit" disabled={!isValid}>
              Save
            </Button>
          </>
        );
      default:
        return (
          <>
            {loadingUploadDoc ? (
              <LinearProgress value={uploadProgress[fileHash]} />
            ) : (
              <UploadDocComponent
                label={`Browse ${
                  type === 'template'
                    ? 'contract template'
                    : 'support documents'
                } to upload`}
                control={control}
                name="file"
                allowedFileTypes={['.docx']}
                files={uploadedFile}
                setFiles={setUploadedFile}
                required
              />
            )}
            {type === 'template' ? (
              <RISelectComponent
                required
                name="contract_type"
                control={control}
                labelKey="displayName"
                label="Select a contract type"
                options={sortListAlphabetical(contracts, 'displayName')}
                loading={contractsLoading}
              />
            ) : (
              <RISelectComponent
                required
                name="template_type"
                control={control}
                label="select template type"
                options={sortListAlphabetical(selectTypeDocument)}
                valueKey="name"
              />
            )}
            <RISelectComponent
              name="tags"
              control={control}
              label="Tags"
              options={sortListAlphabetical(tags) || []}
              loading={tagsLoading}
              isMultiselect
              renderCustomComponent={(value: any, props) => (
                <CustomChip
                  {...props}
                  icon={
                    <TagIcon
                      style={{
                        color: '#6D264C',
                      }}
                    />
                  }
                  label={value?.name}
                />
              )}
              canCreateNew
              addNewValue={(value) => addTagsMutation({ tag_name: value })}
            />
            <RISelectComponent
              name="groups"
              control={control}
              label="Groups *"
              options={sortListAlphabetical(groups)}
              loading={groupLoading}
              isMultiselect={true}
              renderCustomComponent={(value: any) => (
                <CustomChip key={value?.id} label={value?.name} />
              )}
            />
            <ControlledTextField
              required
              name="name"
              control={control}
              label="Template Name"
              fullWidth
            />
            {type === 'support_doc_template' && (
              <Stack gap={1}>
                <Typography fontWeight={'700'}>
                  (optional) Linked Template
                </Typography>
                <RISelectComponent
                  name="linked_template"
                  control={control}
                  label="Select a linked template"
                  options={templates}
                  loading={templatesLoading}
                />
              </Stack>
            )}
            <Button
              variant="contained"
              type="submit"
              disabled={
                !groupsField?.length || !isValid || !uploadedFile?.length
              }
            >
              Save and close
            </Button>
          </>
        );
    }
  }, [
    addTagsMutation,
    contracts,
    contractsLoading,
    control,
    fileHash,
    groupLoading,
    groups,
    groupsField?.length,
    isValid,
    loadingUploadDoc,
    tags,
    tagsLoading,
    templates,
    templatesLoading,
    type,
    uploadProgress,
    uploadedFile,
  ]);

  return (
    <CustomModal
      title={
        type === 'template'
          ? 'Upload Contract template'
          : 'Upload Support Document template'
      }
      open={open}
      handleClose={onClose}
      maxWidth="md"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3} sx={{ minWidth: '600px', padding: '30px 10px' }}>
            {renderFormFields()}
          </Stack>
        </form>
      </FormProvider>
    </CustomModal>
  );
};

export default CreateTemplate;
