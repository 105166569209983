import React, { Dispatch, SetStateAction, useMemo, useState } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { GridActionsCellItem, GridColDef, GridRowId } from '@mui/x-data-grid';
import { useDebouncedCallback } from 'use-debounce';

import CreateRolesModal from './CreateRolesModal';
import { IObject } from '../interfaces/IObject';
import DataGridTable from '../RiverusUI/DataGrid/DataGridTable';
import { GridCellExpand } from '../RiverusUI/DataGrid/GridCellExpand';

interface Props {
  setSelectedRow: Dispatch<SetStateAction<GridRowId[]>>;
  selectedRow: GridRowId[];
  data: any;
  isLoading: boolean;
  pageNumber: number;
  setPageNumberChange: Dispatch<SetStateAction<number>>;
  setFilters: Dispatch<SetStateAction<IObject>>;
  deleteRole: (ids: GridRowId[]) => void;
  editRole: any;
  filters?: IObject;
  editDetails?: boolean;
}

const RolesTable = (props: Props) => {
  const {
    setSelectedRow,
    selectedRow,
    data,
    isLoading,
    pageNumber,
    setPageNumberChange,
    setFilters,
    deleteRole,
    editRole,
    filters,
    editDetails,
  } = props;

  const isActionDisabled = useMemo<boolean>(
    () => selectedRow.length > 1,
    [selectedRow]
  );
  const [openCreateRole, setOpenCreateRole] = useState<boolean>(false);

  const handleFilterChange = useDebouncedCallback(
    (label: string, value: string | string[]) => {
      if (value?.length) {
        setPageNumberChange(0);
      }
      setFilters((prev: IObject) => ({ ...prev, [label]: value }));
    },
    1000
  );

  const handleCloseOfViewPolicy = () => {
    setOpenCreateRole(false);
  };

  const columns = React.useMemo<GridColDef<any>[]>(
    () => [
      {
        field: 'roles',
        headerName: 'Roles',
        minWidth: 80,
        flex: 8,
        sortable: false,
        renderCell: (Params) => {
          return (
            <GridCellExpand
              value={Params?.row?.name}
              width={Params?.colDef?.computedWidth}
            />
          );
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        type: 'actions',
        minWidth: 150,
        flex: 2,
        getActions: (params) => [
          <GridActionsCellItem
            key="edit"
            icon={<EditOutlinedIcon />}
            label="Edit"
            disabled={isActionDisabled}
            onClick={() => editRole(params?.row)}
          />,
          <GridActionsCellItem
            key="delete"
            icon={<DeleteOutlineOutlinedIcon />}
            label="Delete"
            disabled={isActionDisabled}
            onClick={() => deleteRole([params?.row?.id])}
          />,
        ],
      },
    ],
    [
      data,
      filters,
      handleFilterChange,
      setFilters,
      isActionDisabled,
      editRole,
      deleteRole,
    ]
  );

  return (
    <>
      <DataGridTable
        rows={data || []}
        columns={columns}
        rowCount={data?.length}
        isLoading={isLoading}
        setSelectedRow={setSelectedRow}
        pageNumber={pageNumber}
        setPageNumberChange={setPageNumberChange}
        checkboxSelection={false}
        hideFooterPagination
      />
      {openCreateRole && (
        <CreateRolesModal
          open={openCreateRole}
          onClose={handleCloseOfViewPolicy}
          editDetails={editDetails}
        />
      )}
    </>
  );
};

export default RolesTable;
