import AUTH0 from '../../auth_config';
import { authApi } from '../../Services/authApi';

const APIPaths = {
  postContractMetaData: `${AUTH0.apiBasePath}stylus/draft/`,
  getContractsTags: `${AUTH0.apiBasePath}contracts/tags/`,
  compareVersion: `${AUTH0.apiBasePath}stylus/compare_version/`,
};

export const uploadContractMetaData = (post: any) =>
  authApi.post(APIPaths.postContractMetaData, post).catch((error) => {
    console.error('uploadContractMetaData', error);
  });

export const getContractsTags = () =>
  authApi
    .get(APIPaths.getContractsTags)
    .then((response) => response)
    .catch((error) => console.error(error));

export const uploadCompareVersion = (payload: any) => {
  return authApi
    .post(APIPaths.compareVersion, payload)
    .then((response) => response)
    .catch((error) => {
      console.error('uploadSupportDoc', error);
    });
};
