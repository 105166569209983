import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Docxtemplater from 'docxtemplater';
import { useSnackbar } from 'notistack';
import PizZip from 'pizzip';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import DraftForm from './DraftForm';
import MultiUploadNotes from './MultiUploadNotes';
import { formTypeOptions } from './staticData';
import { useUserData } from '../../../App/Component/UserDataProvider';
import CustomChip from '../../../Approvals/Component/CreateApprovalForm/CustomChip';
import { Blank_Document_link } from '../../../Constants/const';
import { isRiverusAdmin } from '../../../DataDictionary/DDUtils';
import ControlledTextField from '../../../RiverusUI/Components/ControlledTextField';
import NotepadComponent from '../../../RiverusUI/Components/NotepadComponent';
import RadioButtonGroup from '../../../RiverusUI/Components/RadioButtonGroup';
import RISelectComponent from '../../../RiverusUI/Components/SelectComponent';
import {
  fetchAllContractCategory,
  fetchContractType,
  fetchDraftGroups,
  fetchGroups,
  fetchRequestApprovals,
} from '../../../Services/Approval';
import { fetchContractById } from '../../../Services/DocumentLibrary';
import {
  createDraft,
  fetchPolicyContractTypes,
  fetchTemplate,
  getDraftById,
  getS3PresignedUrl,
  getS3ReferencePresignedURL,
  referenceDocument,
  sendEmail,
  upload_file_in_s3_bucket,
} from '../../../Services/Draft';
import { fetchInternalUsers } from '../../../Services/Share';
import { draftStatus } from '../../State/DraftState';
import { sortListAlphabetical } from '../Helper';
import LinkSelectedTemplateField from '../TemplateDrawer/LinkSelectedTemplateField';

interface IProps {
  onClose: VoidFunction;
}

const SparkMD5 = require('spark-md5');

const CreateDraft: React.FC<IProps> = ({ onClose }) => {
  const [formTypes, setFormTypes] = useState(formTypeOptions);
  const [counterPartyDraft, setCounterPartyDraft] = useState<any>([]);
  const [selectedLinkedDraft, setSelectedLinkedDraft] = useState<any>(null);
  const [formData, setFormData] = useState<any>();
  const [uploadedFile, setUploadedFile] = useState<any[]>([]);
  const [draftDetails, setDraftDetails] = useState<any>({});
  const [allReferenceDocs, setAllReferenceDocs] = useState<any[]>([]);
  const [ownReferenceFileData, setOwnReferenceFileData] = useState<any>({});
  const [fixedGroups, setFixedGroups] = useState<any[]>([]);
  const [draftPayloadLink, setDraftPayloadLink] = useState<any[]>([]);
  const [completedUploads, setCompletedUploads] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [contractOwners, setContractOwners] = useState<any[]>([]);

  const draftTriggeredRef = useRef(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { search } = useLocation();
  const queryParams: any = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const queryParamsContractId = queryParams.get('contract_id');
  const is_renewed = queryParams.get('is_renewed');

  const methods = useForm();

  const { handleSubmit, control, watch, resetField, setValue } = methods;

  const form_type = watch('createFrom');
  const contract_type = watch('contractType');
  const template = watch('template');
  const draft_type = watch('draft_type');
  const can_upload_draft = watch('can_upload_draft');
  const can_link_draft = watch('can_link_draft');
  const selectedApprovers = watch('approvalTypes');
  const contract_category = watch('contract_category') || '';

  useEffect(() => {
    resetField('createFrom');
  }, [contract_type, resetField]);

  useEffect(() => {
    if (!contract_type) {
      resetField('contract_category');
    }
  }, [contract_type, resetField]);

  const { user_data, user_id, userIsCreator } = useUserData();

  const { data: umbrellaContractData } = useQuery({
    queryKey: ['get_contract_data_by_id', queryParamsContractId],
    queryFn: () => fetchContractById(queryParamsContractId),
    enabled: !!queryParamsContractId,
  });

  const { data: draft_data } = useQuery({
    queryKey: ['get_draft_data_by_id', umbrellaContractData?.draft],
    queryFn: () => getDraftById(umbrellaContractData?.draft),
    enabled: !!umbrellaContractData?.draft,
  });

  const { data: version0Data } = useQuery({
    queryKey: ['get_version_0_draft_by_id', draft_data?.draftID],
    queryFn: () => getDraftById(draft_data?.draftID),
    enabled: !!draft_data?.draftID,
  });

  useEffect(() => {
    if (umbrellaContractData?.contract_type?.length) {
      setValue('contractType', umbrellaContractData?.contract_type?.[0]?.id);
    }

    if (umbrellaContractData?.owners?.length) {
      const owners = umbrellaContractData?.owners?.map(
        (owner: any) => owner?.id
      );
      setContractOwners(owners);
    } else if (umbrellaContractData?.creator_details?.id) {
      setContractOwners([umbrellaContractData?.creator_details?.id]);
    }
  }, [umbrellaContractData]);

  const { data: internalUsers } = useQuery({
    queryKey: ['get-internal-users'],
    queryFn: fetchInternalUsers,
    select: (response: any) => {
      const groups = response?.map((data: any) => ({
        ...data,
        name: data.first_name + ' ' + data.last_name,
      }));
      return groups;
    },
  });

  const { data: contractData, isLoading: contractLoading } = useQuery({
    queryKey: ['contract_data'],
    queryFn: fetchContractType,
    select: (response: any) => response.results,
    enabled: !userIsCreator,
  });

  const { data: contractCategory, isLoading: categoryLoading } = useQuery({
    queryKey: ['contract_category_data'],
    queryFn: fetchAllContractCategory,
    select: (response: any) => response.results,
  });

  const { data: creatorContractType, isLoading: contractTypeLoading } =
    useQuery({
      queryKey: ['creator_contract_type'],
      queryFn: fetchPolicyContractTypes,
      select: (response: any) => response.results,
      enabled: !!userIsCreator,
    });

  const { data: contractRequestData } = useQuery({
    queryKey: ['contract_type_has_policy', contract_type, contract_category],
    queryFn: () => {
      let params = `?approval_type=contract_request&contract=${contract_type}`;
      if (contract_category) {
        params += `&contract_category=${contract_category}`;
      }
      return fetchRequestApprovals(params);
    },
    select: (response: any) => {
      const filterContractType = response?.results?.filter((item: any) => {
        if (item?.contract_category?.length) {
          return item?.contract_category?.some(
            (categoryItem: any) => categoryItem?.id === contract_category
          );
        } else {
          return item;
        }
      });
      if (response?.results) {
        response.results = filterContractType;
      }
      return response;
    },
    enabled: !!contract_type,
  });

  const { data: creatorCategory } = useQuery({
    queryKey: ['contract_category_has_policy', contract_type],
    queryFn: () => {
      const params = `?approval_type=contract_request&contract=${contract_type}`;
      return fetchRequestApprovals(params);
    },
    select: (response: any) => {
      const filterCategory = response?.results
        ?.map((item: any) => {
          const categoryItem = item?.contract_category?.filter(
            (category: any) =>
              category?.contract_types?.some(
                (type: any) => type?.id === contract_type
              )
          );
          return categoryItem;
        })
        .flat();
      const isCategoryRequired = response?.results?.filter(
        (item: any) => !item?.contract_category?.length
      );
      return {
        isCategoryRequired: isCategoryRequired?.length ? false : true,
        creatorCategoryData: filterCategory,
      };
    },
    enabled: !!(contract_type && userIsCreator),
  });

  const { data: groupListData, isLoading: groupLoading } = useQuery({
    queryKey: ['GroupList'],
    queryFn: fetchGroups,
  });

  const { data: draftGroups } = useQuery({
    queryKey: ['draft_groups', contract_type],
    queryFn: async () => await fetchDraftGroups(contract_type),
    select: (response: any) => response?.groups,
    enabled: !!contract_type,
  });

  useEffect(() => {
    const groups = [
      ...new Set([...(draftGroups || []), ...(user_data?.groups || [])]),
    ];
    setValue('groups', groups);
    setFixedGroups(groups);
  }, [draftGroups, setValue, user_data?.groups]);

  const { data: getTemplateList, isLoading: loadingTemplateList } = useQuery({
    queryKey: ['template_list'],
    queryFn: async () => {
      const response = await fetchTemplate();
      return response?.results;
    },
  });

  const templateList = useMemo(() => {
    return Array.isArray(getTemplateList)
      ? getTemplateList?.filter(
          (items: any) => items?.contract_type?.id === contract_type
        )
      : [];
  }, [getTemplateList, contract_type]);

  const selectedContractData = useMemo(() => {
    if (userIsCreator) {
      const selectedContract = creatorContractType?.filter(
        (data: any) => data.id === contract_type
      );
      return selectedContract?.[0];
    } else {
      const selectedContract = contractData?.filter(
        (data: any) => data.id === contract_type
      );
      return selectedContract?.[0];
    }
  }, [contractData, contract_type, creatorContractType]);

  useEffect(() => {
    if (contract_type && templateList) {
      const updateDraftOptions = [...formTypeOptions];
      if (templateList?.length === 0) {
        const option = {
          value: 'template',
          title: '',
          description: `No templates found under contract type “${selectedContractData?.name}".`,
          disabled: true,
        };
        updateDraftOptions[0] = option;
      } else {
        updateDraftOptions[0].disabled = false;
      }
      setFormTypes(updateDraftOptions);
    }
  }, [selectedContractData, contract_type, templateList]);

  const selectedTemplate = React.useMemo(() => {
    const temp = templateList?.filter((data: any) => data.id === template);
    return temp?.[0];
  }, [template, templateList]);

  useEffect(() => {
    if (contract_type === version0Data?.contractType?.id) {
      setValue('contract_category', version0Data?.contract_category?.id);
      if (umbrellaContractData?.renewal_draft_type === 'template') {
        setValue('createFrom', 'template');
        const tempData = templateList?.find(
          (data: any) => data?.file_path === version0Data?.template_link
        );
        setValue('template', tempData?.id);
      } else if (umbrellaContractData?.renewal_draft_type === 'draft') {
        setValue('createFrom', 'draft');
        setValue('draft_type', 'request_draft');
      } else if (is_renewed) {
        setValue('createFrom', 'draft');
        setValue('draft_type', 'request_draft');
        setValue(
          'approvalTypes',
          version0Data?.owners?.map((owner: any) => owner?.id)
        );
        setValue('counter_party_name', version0Data?.counter_party_name);
        setValue('notes', version0Data?.notes);
        setValue('can_link_draft', true);
        setValue('linked_draft', queryParamsContractId);
      } else if (
        version0Data?.createFrom === 'counter_party' ||
        version0Data?.createFrom === 'request_draft'
      ) {
        setValue('createFrom', 'draft');
        setValue('draft_type', version0Data?.createFrom);
        setValue(
          'approvalTypes',
          version0Data?.owners?.map((owner: any) => owner?.id)
        );
        setValue('counter_party_name', version0Data?.counter_party_name);
        setValue('notes', version0Data?.notes);
        if (
          version0Data?.earlier_draft_link ||
          version0Data?.executed_contract_link
        ) {
          setValue('can_link_draft', true);
          setValue(
            'linked_draft',
            version0Data?.earlier_draft_link ||
              version0Data?.executed_contract_link
          );
        }
      }
    }
  }, [version0Data, contract_type, templateList]);

  const handleUploadAllRefDoc = (refPayload: any[] = []) => {
    const reference_documents: any[] = [];
    if (draft_type !== 'request_draft') {
      uploadedFile?.map((file: any) => {
        const fileData = {
          file_name: file?.[0]?.name,
          file_type: 'note_for_approval',
        };
        reference_documents.push(fileData);
      });
    }

    const payload = {
      reference_documents: [...refPayload, ...reference_documents],
      file_type: 'reference_document',
    };

    uploadReferenceDocument({ body: payload });
  };

  const uploadBlankDoc = async (data: any) => {
    if (data) {
      const reference_documents: any[] = [];
      let noteFile: any[] = [];
      let policyFile: any[] = [];

      const processDocument = async (
        content: string,
        file_name_suffix: string,
        file_type: string
      ) => {
        const templateFile = await fetch(Blank_Document_link.NOTES_BLANK_DOC);
        const templateData = await templateFile.arrayBuffer();
        const zip = new PizZip(templateData);
        const doc = new Docxtemplater().loadZip(zip);
        const docContent = content.replace(/<\/?[^>]+>/g, '\n');
        doc.setData({ content: docContent });
        doc.render();

        const generatedDocx = doc.getZip().generate({
          type: 'blob',
          mimeType:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });

        const file = generatedDocx;
        file['name'] = `Additional Requirements_${file_name_suffix}.docx`;
        const reader = new FileReader();

        if (file) {
          reader.readAsDataURL(file);
          reader.onload = async () => {
            reference_documents.push({
              file_name: file?.name,
              file_type: file_type,
            });
            if (file_type === 'draft_notes') {
              noteFile = [file];
            } else if (file_type === 'policy_notes') {
              policyFile = [file];
            }
          };
        }

        return new Promise<void>((resolve) => {
          reader.onloadend = () => {
            resolve();
          };
        });
      };

      if (data?.notes && draft_type !== 'request_draft') {
        await Promise.all([
          processDocument(data?.notes, 'notes', 'draft_notes'),
        ]);

        if (!data?.policy_note) {
          setAllReferenceDocs([noteFile, ...uploadedFile]);
          handleUploadAllRefDoc(reference_documents);
        }
      }

      if (data?.policy_note) {
        await Promise.all([
          processDocument(data?.policy_note, 'policy_notes', 'policy_notes'),
        ]);
        if (draft_type === 'request_draft') {
          const allReferenceDocs = [
            ...(uploadedFile ? uploadedFile : []),
            ...(data?.notes ? [noteFile] : []),
            ...(data?.policy_note ? [policyFile] : []),
            ...(can_upload_draft ? [counterPartyDraft] : []),
          ];
          setAllReferenceDocs(allReferenceDocs);
        } else {
          if (!data?.notes) {
            setAllReferenceDocs([policyFile, ...uploadedFile]);
          } else {
            setAllReferenceDocs([noteFile, policyFile, ...uploadedFile]);
          }
        }
        handleUploadAllRefDoc(reference_documents);
      }
    }
  };

  const getFileType = (url: string) => {
    if (url.includes('draft_notes')) {
      return 'draft_notes';
    } else if (url.includes('policy_notes')) {
      return 'policy_notes';
    } else if (url.includes('note_for_approval')) {
      return 'note_for_approval';
    } else if (url.includes('earlier_draft')) {
      return 'earlier_draft';
    } else if (url.includes('own_reference_document')) {
      return 'own_reference_document';
    }
  };

  const { mutate: emailMutation } = useMutation({
    mutationKey: ['send_email'],
    mutationFn: sendEmail,
    onSuccess: (response: any) => {
      if (response?.message !== 'No Emails Sent') {
        enqueueSnackbar('Email sent successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      }
      onClose();
      window.open(`/draft/${draftDetails?.version}/${draftDetails?.id}`);
      if (draftDetails?.renewal_contract_id) {
        navigate('/draftingreview');
      }
      queryClient.invalidateQueries({
        queryKey: ['draft_statistics'],
      });
      queryClient.invalidateQueries({
        queryKey: ['drafts'],
      });
    },
    onError: () => {
      enqueueSnackbar('Email is not sent successfully!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: createDraftMutation, isPending: createDraftLoading } =
    useMutation({
      mutationKey: ['create_draft'],
      mutationFn: createDraft,
      onSuccess: async (response: any) => {
        setDraftDetails(response);
        enqueueSnackbar('Draft added successfully!', {
          variant: 'success',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });

        if (draft_type === 'request_draft') {
          setDraftDetails(response);
          if (response?.policy_note) {
            await uploadBlankDoc(response);
          }
          if (!response?.policy_note) {
            const processFiles = async () => {
              const referencePresignedUrls: any[] = [];
              await Promise.all(
                draftPayloadLink?.map(
                  async (presigned_url: any, index: number) => {
                    const fileData = allReferenceDocs?.[index]?.[0];
                    if (fileData) {
                      const reader = new FileReader();
                      const hexHash = await new Promise<string>((resolve) => {
                        reader.readAsDataURL(fileData);
                        reader.onload = () => {
                          const hash = SparkMD5.hash(reader.result);
                          resolve(hash);
                        };
                      });

                      const file_ = {
                        file_name: presigned_url?.file_name,
                        file_type: getFileType(presigned_url?.file_path),
                        file_hash: hexHash,
                        file_size: fileData?.size,
                        draft: response?.draftID,
                        link: presigned_url?.file_path,
                      };
                      referencePresignedUrls.push(file_);
                    }
                  }
                )
              );
              const processFileType = (fileType: string) => {
                const file = draftPayloadLink?.find(
                  (url: any) => url?.file_type === fileType
                );
                if (file) {
                  const fileData = {
                    file_name: file?.file_name,
                    file_type: file?.file_type,
                    draft: response?.draftID,
                    link: file?.file_path,
                    file_size: selectedLinkedDraft?.file_size,
                    file_hash: selectedLinkedDraft?.file_hash,
                  };
                  referencePresignedUrls.push(fileData);
                }
              };
              processFileType('earlier_draft');
              processFileType('executed_contract');
              if (referencePresignedUrls.length > 0) {
                uploadReferenceDocLinkinDraft(referencePresignedUrls);
              }
            };
            await processFiles();
          }
        } else {
          if (
            !response?.policy_note &&
            !response?.notes &&
            uploadedFile?.length === 0 &&
            !can_upload_draft
          ) {
            const payload: any = {
              draft: response?.draftID,
            };
            emailMutation(payload);
          } else {
            setDraftDetails(response);
            if (response?.notes || response?.policy_note) {
              uploadBlankDoc(response);
            } else if (uploadedFile?.length > 0) {
              handleUploadAllRefDoc();
              setAllReferenceDocs([...uploadedFile]);
            } else if (can_upload_draft) {
              const payload = [
                {
                  ...ownReferenceFileData,
                  draft: response?.draftID,
                },
              ];
              uploadReferenceDocLinkinDraft(payload);
            }
          }
        }
      },
      onError: (error: any) => {
        const responseData = error?.response?.data?.non_field_errors?.[0];
        enqueueSnackbar(`${responseData || 'Failed to create Draft!'}`, {
          variant: 'error',
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        });
      },
    });

  const { mutate: uploadFileInS3Bucket, isPending: loadingS3 } = useMutation({
    mutationKey: ['upload_draft_to_s3'],
    mutationFn: upload_file_in_s3_bucket,
    onSuccess: (response: any) => {
      if (draft_type !== 'request_draft') {
        createDraftMutation({ ...formData, link: response.file_path });
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadReferenceFileInS3Bucket } = useMutation({
    mutationKey: ['upload_draft_to_s3'],
    mutationFn: upload_file_in_s3_bucket,
    onSuccess: () => {
      setCompletedUploads((prev) => prev + 1);
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const { mutate: uploadReferenceDocLinkinDraft } = useMutation({
    mutationKey: ['upload_reference_doc_link_in_draft'],
    mutationFn: referenceDocument,
    onSuccess: () => {
      const payload: any = {
        draft: draftDetails?.draftID,
        notes: true,
      };
      emailMutation(payload);
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const onUploadProgress = React.useCallback(
    (progressEvent: any) => {
      const reader = new FileReader();
      let uploadProgress: any = { hexHash: 0 };
      const file = counterPartyDraft[0];
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = async () => {
          const hexHash = SparkMD5.hash(reader.result);
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          uploadProgress = {
            ...uploadProgress,
            [hexHash]: percentCompleted,
          };
        };
      }
    },
    [counterPartyDraft]
  );

  const { mutate: uploadDocument, isPending: loadingUpload } = useMutation({
    mutationKey: ['upload_draft_document', counterPartyDraft],
    mutationFn: getS3PresignedUrl,
    onSuccess: (response: any) => {
      if (response) {
        if (can_upload_draft) {
          setOwnReferenceFileData((prevState: any) => ({
            ...prevState,
            link: response?.presigned_url.file_path,
          }));
        }
        const file = counterPartyDraft[0];
        if (file) {
          const onHandleFileProgress = {
            onUploadProgress: (progressEvent: any) =>
              onUploadProgress(progressEvent),
          };
          uploadFileInS3Bucket({
            presignedPostData: response?.presigned_url,
            file: file,
            onHandleFileProgress: onHandleFileProgress,
          });
        }
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  useEffect(() => {
    if (
      completedUploads === totalFiles &&
      totalFiles > 0 &&
      draft_type === 'request_draft' &&
      !draftTriggeredRef.current
    ) {
      draftTriggeredRef.current = true;
      const noteForApprovalFile =
        draftPayloadLink?.find(
          (file) => file?.file_type === 'note_for_approval'
        ) ||
        draftPayloadLink?.find((file) => file?.file_type === 'draft_notes');
      const link = noteForApprovalFile?.file_path;
      const payload = {
        ...formData,
        ...(formData.draft_type === 'request_draft' && { link: link }),
      };
      createDraftMutation(payload);
    }
  }, [completedUploads, totalFiles, draftPayloadLink]);

  const { mutate: uploadReferenceDocument } = useMutation({
    mutationKey: ['upload_reference_document'],
    mutationFn: getS3ReferencePresignedURL,
    onSuccess: async (response: any) => {
      const presigned_urls = response?.response?.presigned_url;

      setDraftPayloadLink(presigned_urls);
      if (presigned_urls) {
        const referencePresignedUrls: any = [];
        const filteredReferenceDocs = allReferenceDocs?.filter(
          (docArray) => docArray.length > 0
        );
        const totalFilesCount = filteredReferenceDocs?.length || 0;
        setTotalFiles(totalFilesCount);

        if (
          presigned_urls?.some(
            (urls: any) => urls?.file_type === 'policy_notes'
          ) &&
          draft_type === 'request_draft'
        ) {
          const processDocument = async (content: string): Promise<Blob> => {
            const templateFile = await fetch(
              Blank_Document_link.NOTES_BLANK_DOC
            );
            const templateData = await templateFile.arrayBuffer();
            const zip = new PizZip(templateData);
            const doc = new Docxtemplater().loadZip(zip);
            const docContent = content.replace(/<\/?p>/g, '');
            doc.setData({ content: docContent });
            doc.render();
            return doc.getZip().generate({
              type: 'blob',
              mimeType:
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            });
          };

          const handleDocumentUpload = async (
            content: string,
            file_type: string,
            presigned_url: any
          ) => {
            const policyBlob = await processDocument(content);
            const fileData = new File(
              [policyBlob],
              `Additional Requirements_${file_type}.docx`,
              {
                type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              }
            );
            const onHandleFileProgress = {
              onUploadProgress: (progressEvent: any) =>
                onUploadProgress(progressEvent),
            };
            await uploadReferenceFileInS3Bucket({
              presignedPostData: presigned_url,
              file: fileData,
              onHandleFileProgress: onHandleFileProgress,
            });
          };
          const combinedData = [...presigned_urls, ...draftPayloadLink];
          combinedData.forEach((presigned_url: any) => {
            if (presigned_url.file_type === 'policy_notes') {
              handleDocumentUpload(
                draftDetails?.policy_note,
                'policy_notes',
                presigned_url
              );
            }
          });

          const uniqueFileMap = new Set();
          combinedData.forEach((presigned_url: any, index: number) => {
            if (
              presigned_url?.file_type === 'executed_contract' ||
              presigned_url?.file_type === 'earlier_draft'
            ) {
              const file_ = {
                file_name: presigned_url?.file_name,
                file_type: presigned_url?.file_type,
                file_size: selectedLinkedDraft?.file_size,
                file_hash: selectedLinkedDraft?.file_hash,
                draft: draftDetails?.draftID,
                link: presigned_url?.file_path,
              };

              const fileKey = `${file_.file_name}-${file_.file_type}`;
              if (!uniqueFileMap.has(fileKey)) {
                uniqueFileMap.add(fileKey);
                referencePresignedUrls.push(file_);
              }
            }

            allReferenceDocs?.forEach((fileData: any, i: number) => {
              if (index === i) {
                const reader = new FileReader();
                const file = fileData[0];
                if (file) {
                  reader.readAsDataURL(file);
                  reader.onload = () => {
                    const hexHash = SparkMD5.hash(reader.result);
                    const file_ = {
                      file_name: presigned_url?.file_name,
                      file_type: getFileType(presigned_url?.file_path),
                      file_hash: hexHash,
                      file_size: file?.size,
                      draft: draftDetails?.draftID,
                      link: presigned_url?.file_path,
                    };
                    const fileKey = `${file_.file_name}-${file_.file_hash}`;
                    if (!uniqueFileMap.has(fileKey)) {
                      uniqueFileMap.add(fileKey);
                      referencePresignedUrls.push(file_);
                    }
                  };
                }
              }
            });
          });
          setTimeout(() => {
            if (referencePresignedUrls.length > 0) {
              uploadReferenceDocLinkinDraft(referencePresignedUrls);
            }
          }, 2000);
        } else {
          presigned_urls?.map((presigned_url: any, index: number) => {
            allReferenceDocs?.map((fileData: any, i: number) => {
              if (index === i) {
                const reader = new FileReader();
                const file = fileData[0];
                if (file) {
                  reader.readAsDataURL(file);
                  const onHandleFileProgress = {
                    onUploadProgress: (progressEvent: any) =>
                      onUploadProgress(progressEvent),
                  };
                  uploadReferenceFileInS3Bucket({
                    presignedPostData: presigned_url,
                    file: file,
                    onHandleFileProgress: onHandleFileProgress,
                  });
                  reader.onload = async () => {
                    const hexHash = SparkMD5.hash(reader.result);
                    const file_ = {
                      file_hash: hexHash,
                      file_name: file.name,
                      file_size: file.size,
                      file_type: getFileType(presigned_url?.file_path),
                      draft: draftDetails?.draftID,
                      link: presigned_url?.file_path,
                    };
                    referencePresignedUrls.push(file_);
                    if (
                      presigned_urls?.length - 1 === index &&
                      draft_type !== 'request_draft'
                    ) {
                      if (can_upload_draft) {
                        const payload = {
                          ...ownReferenceFileData,
                          draft: draftDetails?.draftID,
                        };
                        referencePresignedUrls.push(payload);
                      }
                      uploadReferenceDocLinkinDraft(referencePresignedUrls);
                    }
                  };
                }
              }
            });
          });
        }
      }
    },
    onError: () => {
      enqueueSnackbar('Failed to upload document!', {
        variant: 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'right' },
      });
    },
  });

  const contractApproversId = useMemo(() => {
    return contractRequestData?.results?.[0]?.approvers?.map(
      (approver: any) => approver?.id
    );
  }, [contractRequestData]);

  const generatePresignedPayload = async (
    uploadedFile: File[],
    counterPartyDraft?: File[],
    selectedLinkedDraft?: any,
    data?: any
  ) => {
    const referenceDocuments: any[] = [];
    let noteFile: any[] = [];
    let policyFile: any[] = [];
    let linkParam: any = null;

    if (uploadedFile?.length > 0) {
      uploadedFile?.forEach((fileArray: any) => {
        const file = fileArray[0];
        referenceDocuments.push({
          file_name: file?.name,
          file_type: 'note_for_approval',
        });
      });
    }

    const processDraftNotes = async (
      content: string,
      suffix: string,
      file_type: string
    ) => {
      const templateFile = await fetch(Blank_Document_link.NOTES_BLANK_DOC);
      const templateData = await templateFile.arrayBuffer();
      const zip = new PizZip(templateData);
      const doc = new Docxtemplater().loadZip(zip);
      doc.setData({ content: content.replace(/<\/?p>/g, '') });
      doc.render();
      const generatedDocx = doc.getZip().generate({
        type: 'blob',
        mimeType:
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });
      const file = new File(
        [generatedDocx],
        `Additional Requirements_${suffix}.docx`
      );

      if (file_type === 'draft_notes') {
        noteFile = [file];
        referenceDocuments.push({
          file_name: file?.name,
          file_type: 'draft_notes',
        });
      } else if (file_type === 'policy_notes') {
        policyFile = [file];
        referenceDocuments.push({
          file_name: file?.name,
          file_type: 'policy_notes',
        });
      }
      return file;
    };
    if (data?.notes)
      await processDraftNotes(data.notes, 'notes', 'draft_notes');
    if (data?.policy_note)
      await processDraftNotes(data.policy_note, 'policy_notes', 'policy_notes');

    if (can_upload_draft) {
      counterPartyDraft?.forEach((file: File) => {
        referenceDocuments.push({
          file_name: file?.name,
          file_type: 'own_reference_document',
        });
      });
    }

    if (can_link_draft && selectedLinkedDraft) {
      if (selectedLinkedDraft?.link) {
        const fileExtension = selectedLinkedDraft?.link?.split('.').pop();
        linkParam = { earlier_draft_link: selectedLinkedDraft?.link };
        referenceDocuments.push({
          file_name: `${selectedLinkedDraft?.contractName || selectedLinkedDraft?.title}.${fileExtension}`,
          file_type: 'earlier_draft',
          earlier_draft_link: selectedLinkedDraft?.link,
        });
      } else {
        const fileExtension = selectedLinkedDraft?.file_name.split('.').pop();
        linkParam = {
          executed_contract_link: `${data.linked_draft}.${fileExtension}`,
        };
        referenceDocuments.push({
          file_name: selectedLinkedDraft?.file_name,
          file_type: 'executed_contract',
          executed_contract_link: `${data.linked_draft}.${fileExtension}`,
        });
      }
    }

    const allReferenceDocs = [
      ...uploadedFile,
      ...(data?.notes ? [noteFile] : []),
      ...(data?.policy_note ? [policyFile] : []),
      ...(can_upload_draft ? [counterPartyDraft] : []),
    ];
    const validReferenceDocs = allReferenceDocs.filter((doc) => doc != null);
    setAllReferenceDocs(validReferenceDocs);

    const payload = {
      ...(linkParam || {}),
    };
    setFormData(payload);

    return {
      reference_documents: referenceDocuments,
      file_type: 'reference_document',
    };
  };

  const requisitionContractData = useMemo(() => {
    return creatorContractType?.filter(
      (contractType: any) => contractType?.has_requisition_approval
    );
  }, [creatorContractType]);

  const filteredContractCategory = useMemo(() => {
    return contractCategory?.filter((category: any) =>
      category?.contract_types?.some((type: any) => type?.id === contract_type)
    );
  }, [contractCategory, contract_type]);

  const noContractTypeCategory = useMemo(() => {
    return contractCategory?.filter(
      (category: any) => category?.contract_types?.length === 0
    );
  }, [contractCategory]);

  const combinedContractCategories = useMemo(() => {
    return [
      ...(creatorCategory?.creatorCategoryData || []),
      ...(noContractTypeCategory || []),
      ...(filteredContractCategory || []),
    ];
  }, [
    filteredContractCategory,
    noContractTypeCategory,
    creatorCategory?.creatorCategoryData,
  ]);

  const onSubmit = useCallback(
    async (data: any) => {
      if (!data.groups) {
        data.groups = ['/Org'];
      } else {
        data.groups = data?.groups.filter((group: string) => group !== null);
      }
      delete data.counter_party_draft;
      const commonPayload = {
        status:
          form_type === 'template'
            ? draftStatus.DRAFT_CREATION_PENDING
            : contractRequestData?.results?.length > 0
              ? draftStatus.REQUISITION_APPROVAL_PENDING
              : draftStatus.DRAFT_CREATION_PENDING,
        owners: contractOwners?.length
          ? [user_id, ...contractOwners]
          : user_data?.roles?.includes('Creators') &&
              contractRequestData?.results?.[0]?.approvers?.length
            ? contractApproversId
            : draft_type === 'request_draft' || draft_type === 'counter_party'
              ? [user_id, ...(data?.approvalTypes || [])]
              : [user_id],
        creator: userIsCreator ? user_id : '',
        approvers: contractApproversId || [],
        source: '',
        version: 0,
        renewal_contract_id: umbrellaContractData?.id
          ? umbrellaContractData?.id
          : '',
      };

      const ownerApproverIds = [
        ...commonPayload.owners,
        ...commonPayload.approvers,
      ];

      internalUsers?.forEach((approver: any) => {
        if (ownerApproverIds.includes(approver?.id)) {
          const missingInDraftGroups = approver?.groups.filter(
            (item: string) => !data.groups?.includes(item)
          );
          if (missingInDraftGroups?.length) {
            data.groups.push(...missingInDraftGroups);
          }
        }
      });

      let payload;
      if (form_type === 'template') {
        payload = {
          ...data,
          ...commonPayload,
          template_link: selectedTemplate?.file_path,
        };
        createDraftMutation(payload);
      } else {
        payload = {
          ...data,
          ...commonPayload,
        };
        if (draft_type === 'counter_party') {
          if (!counterPartyDraft[0]) {
            enqueueSnackbar(
              'Please select the counter party draft to continue!',
              {
                variant: 'info',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              }
            );
          } else {
            const reader = new FileReader();
            const file = counterPartyDraft[0];
            if (file) {
              reader.readAsDataURL(file);
              reader.onload = async () => {
                const hexHash = SparkMD5.hash(reader.result);
                const file_ = {
                  file_hash: hexHash,
                  file_name: file.name,
                  file_size: file.size,
                  template_type: file['template_type'],
                  file_type: 'counter_party_drafts',
                  creation_type: isRiverusAdmin() ? 'system' : 'custom',
                };
                uploadDocument(file_);
              };
              payload.createFrom = 'counter_party';
              setFormData(payload);
            }
          }
        } else if (draft_type === 'request_draft') {
          if (
            uploadedFile?.length === 0 &&
            (!data?.notes || data?.notes === '<p><br></p>') &&
            contractRequestData?.count > 0
          ) {
            enqueueSnackbar(
              'Please either upload or type additional requirements!',
              {
                variant: 'info',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              }
            );
            return;
          } else if (
            (!data?.notes || data?.notes === '<p><br></p>') &&
            contractRequestData?.count === 0
          ) {
            enqueueSnackbar('Please type additional requirements!', {
              variant: 'info',
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            });
          }
          if (!selectedApprovers?.length) {
            enqueueSnackbar(
              'Select assignees and then click the Continue button!',
              {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              }
            );
          } else {
            const reference_documents = await generatePresignedPayload(
              uploadedFile,
              counterPartyDraft,
              selectedLinkedDraft,
              data
            );
            let executed_contract_link = null;
            let earlier_draft_link = null;
            if (can_link_draft) {
              if (selectedLinkedDraft?.link) {
                earlier_draft_link = selectedLinkedDraft?.link;
              } else {
                const fileExtension = selectedLinkedDraft?.file_name
                  .split('.')
                  .pop();
                executed_contract_link = `${selectedLinkedDraft?.id}.${fileExtension}`;
              }
            }
            if (reference_documents.reference_documents.length) {
              uploadReferenceDocument({ body: reference_documents });
            }
            payload = {
              ...data,
              ...commonPayload,
              executed_contract_link: executed_contract_link,
              earlier_draft_link: earlier_draft_link,
              createFrom: 'request_draft',
            };
            setFormData(payload);
          }
        }
      }
    },
    [
      contractRequestData,
      counterPartyDraft,
      createDraftMutation,
      draft_type,
      enqueueSnackbar,
      form_type,
      selectedLinkedDraft,
      selectedTemplate,
      uploadDocument,
      user_data,
      user_id,
      userIsCreator,
      uploadedFile,
      generatePresignedPayload,
    ]
  );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ padding: '30px 15px' }}>
          <Grid container gap={2}>
            <Grid item sm={9}>
              <Stack spacing={2}>
                <Typography marginBottom={2} fontWeight={'700'}>
                  Choose a starting point
                </Typography>
                <RISelectComponent
                  required
                  name="contractType"
                  control={control}
                  label="Select a contract type"
                  options={
                    userIsCreator
                      ? sortListAlphabetical(requisitionContractData)
                      : sortListAlphabetical(contractData)
                  }
                  loading={
                    userIsCreator ? contractTypeLoading : contractLoading
                  }
                  renderAction={(value: any) => (
                    <Stack direction="row" alignItems="center">
                      {value?.used_in_templates > 0 && (
                        <Typography variant="body2" color="textSecondary">
                          {`${value.used_in_templates} Template${
                            value.used_in_templates > 1 ? 's' : ''
                          }`}
                        </Typography>
                      )}
                    </Stack>
                  )}
                />
                <RISelectComponent
                  name="contract_category"
                  control={control}
                  label="Contract Category"
                  options={
                    userIsCreator
                      ? sortListAlphabetical(
                          creatorCategory?.creatorCategoryData
                        )
                      : sortListAlphabetical(combinedContractCategories)
                  }
                  loading={categoryLoading}
                  required={
                    userIsCreator && creatorCategory?.isCategoryRequired
                  }
                />
                <RadioButtonGroup
                  row
                  required
                  name="createFrom"
                  options={formTypes}
                  valueKey="value"
                  isDescription
                  control={control}
                />
                {form_type === 'draft' && (
                  <ControlledTextField
                    name="counter_party_name"
                    control={control}
                    label="Counterparty Name"
                    fullWidth
                  />
                )}
              </Stack>
            </Grid>

            {form_type === 'template' && (
              <React.Fragment>
                <Grid item sm={12}>
                  <Stack direction="row" spacing={2}>
                    <KeyboardReturnIcon
                      sx={{
                        transform: 'scaleX(-1)',
                        mt: '15px !important',
                      }}
                    />
                    <LinkSelectedTemplateField
                      control={control}
                      selectedContractId={contract_type}
                      name="template"
                      selectedContractName={selectedContractData?.name}
                      options={sortListAlphabetical(templateList)}
                      loading={loadingTemplateList}
                      type={form_type}
                      selectedDisplayName={selectedContractData?.displayName}
                    />
                  </Stack>
                </Grid>
              </React.Fragment>
            )}
            {contractRequestData?.results?.length > 0 && (
              <Grid item sm={9}>
                <Stack direction="row" spacing={2}>
                  <KeyboardReturnIcon
                    sx={{
                      transform: 'scaleX(-1)',
                      mt: '15px !important',
                    }}
                  />
                  <Box width="100%">
                    <MultiUploadNotes
                      allowedFileTypes={['.pdf', '.docx']}
                      label="Upload note for approval"
                      name="note_for_approval"
                      files={uploadedFile}
                      setFiles={setUploadedFile}
                    />
                  </Box>
                </Stack>
              </Grid>
            )}
            {form_type === 'template' && (
              <Grid container>
                <Grid item xs={9}>
                  <NotepadComponent name="notes" control={control} />
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant="caption"
                    sx={{ paddingLeft: '14px', color: 'rgba(0, 0, 0, 0.6)' }}
                  >
                    Please add additional requirements
                  </Typography>
                </Grid>
              </Grid>
            )}
            {form_type === 'draft' && (
              <DraftForm
                contract_name={selectedContractData?.name}
                contract_type={contract_type}
                display_name={selectedContractData?.displayName}
                counterPartyDraft={counterPartyDraft}
                setCounterPartyDraft={setCounterPartyDraft}
                setSelectedLinkedDraft={setSelectedLinkedDraft}
                contractRequestData={contractRequestData}
                earlier_draft_link={version0Data?.earlier_draft_link}
                executed_contract_link={version0Data?.executed_contract_link}
              />
            )}
            <Grid item sm={9}>
              <Stack spacing={2}>
                <Typography fontWeight={'700'}>Manage Access</Typography>
                <RISelectComponent
                  name="groups"
                  control={control}
                  label="Prescribing Department"
                  valueKey="name"
                  options={sortListAlphabetical(groupListData)}
                  loading={groupLoading}
                  isMultiselect={true}
                  fixedValues={fixedGroups}
                  renderCustomComponent={(value: any) => (
                    <CustomChip key={value?.id} label={value?.name} />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item sm={9}>
              <Stack spacing={2}>
                <Typography fontWeight={'700'}>Name your Draft</Typography>
                <ControlledTextField
                  name="contractName"
                  control={control}
                  required
                  label="Draft Name"
                  fullWidth
                />
              </Stack>
              <Stack direction="row" marginTop={3}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={createDraftLoading || loadingS3 || loadingUpload}
                >
                  Continue
                </LoadingButton>
                <Button variant="outlined" onClick={onClose}>
                  Cancel
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </FormProvider>
  );
};

export default CreateDraft;
