import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import {
  Drawer,
  FormControl,
  FormLabel,
  IconButton,
  Typography,
} from '@mui/material';

import CLauseLibrary from './ClauseLibrary';

interface IProps {
  open: boolean;
  onClose: VoidFunction;
  selectedClause: any;
  handleSelectedValue: (value: any) => void;
}

const ClauseLibraryDrawer: React.FC<IProps> = ({
  open,
  onClose,
  handleSelectedValue,
  selectedClause,
}) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <FormControl component="fieldset" variant="standard">
        <FormLabel
          component="legend"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography>Link a Clause</Typography>
        </FormLabel>
        <CLauseLibrary
          selectedClause={selectedClause}
          handleSelectedValue={handleSelectedValue}
        />
      </FormControl>
    </Drawer>
  );
};

export default ClauseLibraryDrawer;
